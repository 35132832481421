/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { EmailIcon, EmailShareButton, FacebookIcon, XIcon } from 'react-share';
import CrossIcon from '../../assets/images/Cross.svg';
import SearchIcon from '../../assets/images/Search.svg';
import Logo from '../../assets/images/bahanno-logo.svg';
import YtIcon from '../../assets/images/yt.svg';
import InitInfo from '../utils/InitInfo';

const SocialMediaLink = ({ fb, tt, yt }) => (
    <div className="details-social" style={{ float: 'right' }}>
        <ul>
            <li>
                <a href={fb} target="_blank" rel="noreferrer">
                    <FacebookIcon size={32} />
                </a>
            </li>
            <li>
                <a href={tt} target="_blank" rel="noreferrer">
                    <XIcon size={32} />
                </a>
            </li>
            <li>
                <a href={yt} target="_blank" rel="noreferrer">
                    <img src={YtIcon} width={32} alt="Yt Icon" />
                </a>
            </li>
            <li>
                <EmailShareButton
                    url="https://bahannonews.com/"
                    className="Demo__some-network__share-button"
                >
                    <EmailIcon size={32} />
                </EmailShareButton>
            </li>
        </ul>
    </div>
);
export default function TopMenuModal({ show, handleClose }) {
    const { configs, categoryAr } = InitInfo();

    const inp = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const q = new URLSearchParams(location.search).get('q');
    const [searchKey, setSearchKey] = useState(q);
    const onSearchClick = (ev, mode) => {
        const moveToSearch = () => {
            handleClose();
            navigate(`/search/?q=${encodeURIComponent(searchKey)}`);
        };

        if (mode === 'change') {
            setSearchKey(ev.target.value);
        }

        if (mode === 'keyup') {
            if (ev.keyCode === 13) {
                // On press enter
                moveToSearch();
            } else {
                setSearchKey(ev.target.value);
            }
        } else if (mode === 'searchClick') {
            moveToSearch();
        }
    };

    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const tDay = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
        date < 10 ? `0${date}` : `${date}`
    }`;

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
            className="top-menu-modal-content"
            closeButton="closeClass"
        >
            <Modal.Header>
                <Modal.Title id="example-custom-modal-styling-title">
                    <a href="/">
                        <img src={Logo} alt="Logo" style={{ width: 150 }} />
                    </a>
                </Modal.Title>
                <img
                    onClick={handleClose}
                    src={CrossIcon}
                    alt="cross icon"
                    style={{ float: 'right' }}
                />
            </Modal.Header>
            <Modal.Body class="top-menu-modal-body">
                <div className="row">
                    <div className="col top-m-menu-col">
                        <ul>
                            {categoryAr.online &&
                                categoryAr.online.slice(0, 4).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="col top-m-menu-col">
                        <ul>
                            {categoryAr.online &&
                                categoryAr.online.slice(4, 8).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>

                    <div className="col top-m-menu-col">
                        <ul>
                            {categoryAr.online &&
                                categoryAr.online.slice(8, 12).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="col top-m-menu-col">
                        <ul>
                            {categoryAr.online &&
                                categoryAr.online.slice(12, 15).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            {categoryAr.online &&
                                categoryAr.online.slice(16, 16).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            {categoryAr.online &&
                                categoryAr.online.slice(16, 17).map((item) => (
                                    <li key={item.page_id} className="link-area">
                                        <Link to={item.url || '/'} className="link-title">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="col top-m-menu-col">
                        <ul>
                            <li className="link-area" key={7}>
                                <Link to="/news/all/" className="link-title">
                                    সব খবর
                                </Link>
                            </li>
                            <li className="link-area" key={4}>
                                <Link to="/gallery/" className="link-title">
                                    ফটো গ্যালারি{' '}
                                </Link>
                            </li>
                            {/* <li className="link-area" key={5}>
                                <Link to="/" className="link-title">
                                    ভিডিও গ্যালারি{' '}
                                </Link>
                            </li> */}
                            <li className="link-area" key={6}>
                                <Link to={`/archive/${tDay}`} className="link-title">
                                    আর্কাইভ
                                </Link>
                            </li>
                            {/* <li className="link-area" key={7}>
                                <a
                                    href="https://converter.bahannonews.com/"
                                    className="link-title"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    বাংলা কনভার্টার
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="" style={{ width: '74%' }}>
                    <div
                        className={`header-search-group header-search-group-side ${
                            show ? 'header-search-bar2-show' : 'header-search-bar2-hide'
                        }`}
                    >
                        <div className="header-search-text-input header-search-text-input-side">
                            <input
                                ref={inp}
                                placeholder="অনুসন্ধান করুন..."
                                onKeyUp={(ev) => {
                                    onSearchClick(ev, 'keyup');
                                }}
                                value={searchKey || ''}
                                onChange={(ev) => {
                                    onSearchClick(ev, 'change');
                                }}
                            />
                        </div>
                        <button
                            type="button"
                            className="header-search-send"
                            onClick={(ev) => {
                                onSearchClick(ev, 'searchClick');
                            }}
                        >
                            <img src={SearchIcon} alt="Send Icon" />
                        </button>
                    </div>
                </div>
                <div className="" style={{ width: '23%' }}>
                    <SocialMediaLink
                        fb={configs.facebook}
                        tt={configs.twitter}
                        li={configs.linkedin}
                        yt={configs.youtube}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    );
}
