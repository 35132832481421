import store from '../store';

function InitInfo() {
    const storeStates = store.getState();

    return {
        storeStates,

        initData: storeStates?.initData,

        categoryAr: storeStates?.siteData?.categoryAr,

        categoryPageIds: storeStates?.siteData?.categoryPageIds,

        latestArticles: storeStates?.siteData?.latestArticles,

        configs: storeStates?.siteData?.configs,

        activeMenu: storeStates?.activeMenu,

        wcBanner: storeStates?.siteData?.wcBanner,

        loadingStatus: storeStates?.loadingStatus,

        worldCupAr: storeStates?.siteData?.worldCup_all_ar
    };
}

export default InitInfo;
