import React from 'react';
import { Link } from 'react-router-dom';
import { XlHome11 } from '../../../components/AdManagerXl';
import BrowserInfo from '../../../utils/BrowserInfo';
import SideBarNewsWithLead from '../components/SideBarNewsWithLead';
import SectionThumb from './SectionThumb';

function AlbumView({ album }) {
    if (!album) {
        return null;
    }

    return (
        <>
            <div className="inner-overlay" />

            <Link className="lightbox" to={album.link}>
                <img src={album.image} alt="Traffic" className="inner-img2" />
                <span className="tz-gallery-img-title">{album.title}</span>
            </Link>
        </>
    );
}

function HomeSection03MainMd({ articles1, title1, articles2, title2 }) {
    const albumAr = Object.values(articles1).sort((item1, item2) => {
        if (item1.priority < item2.priority) {
            return -1;
        }
        if (item1.priority > item2.priority) {
            return 1;
        }

        return 0;
    });

    return (
        <div className="container">
            <div className="row">
                <div className="col col-840">
                    <div className="row" style={{ marginBottom: 21 }}>
                        <div className="col-12">
                            <div className="home-category-news-title">
                                <div className="home-category-news-title-sub">
                                    <Link to="/gallery/">{title1}</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-md-8 col-lg-8 col-xl-8 inner-p-7">
                                    <div className="img-wrapper2">
                                        <AlbumView album={albumAr[0]} />
                                    </div>
                                </div>

                                <div className="col-6 col-md-4 col-lg-4 col-xl-4 inner-p-0">
                                    <div className="img-wrapper-m img-wrapper2">
                                        <AlbumView album={albumAr[1]} />
                                    </div>
                                </div>

                                <div className="col-6 col-md-4 col-lg-4 col-xl-4  inner-p-7">
                                    <div className="img-wrapper">
                                        <AlbumView album={albumAr[2]} />
                                    </div>
                                </div>

                                <div className="col-6 col-md-4 col-lg-4 col-xl-4 inner-p-0 inner-p-7">
                                    <div className="img-wrapper">
                                        <AlbumView album={albumAr[3]} />
                                    </div>
                                </div>

                                <div className="col-6 col-md-4 col-lg-4 col-xl-4 inner-p-0">
                                    <div className="img-wrapper">
                                        <AlbumView album={albumAr[4]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col col-12">
                    <SectionThumb title={title2} articleAr={articles2} />
                </div>
            </div>
        </div>
    );
}

function HomeSection03MainLg({ articles1, title1 }) {
    const albumAr = Object.values(articles1).sort((item1, item2) => {
        if (item1.priority < item2.priority) {
            return -1;
        }
        if (item1.priority > item2.priority) {
            return 1;
        }

        return 0;
    });

    return (
        <div className="container">
            <div className="row" style={{ marginBottom: 21 }}>
                <div className="col-12">
                    <div className="home-category-news-title">
                        <div className="home-category-news-title-sub">
                            <Link to="/gallery/">{title1}</Link>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-lg-8 col-xl-8 inner-p-7">
                            <div className="img-wrapper2">
                                <AlbumView album={albumAr[0]} />
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 col-xl-4 inner-p-0">
                            <div className="img-wrapper-m img-wrapper2">
                                <AlbumView album={albumAr[1]} />
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 col-xl-4  inner-p-7">
                            <div className="img-wrapper">
                                <AlbumView album={albumAr[2]} />
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 col-xl-4 inner-p-0 inner-p-7">
                            <div className="img-wrapper">
                                <AlbumView album={albumAr[3]} />
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 col-xl-4 inner-p-0">
                            <div className="img-wrapper">
                                <AlbumView album={albumAr[4]} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function HomeSection03Main({ articles1, title1, articles2, title2 }) {
    const albumAr = Object.values(articles1).sort((item1, item2) => {
        if (item1.priority < item2.priority) {
            return -1;
        }
        if (item1.priority > item2.priority) {
            return 1;
        }

        return 0;
    });

    const literatureArtLeadAr = Object.values(articles2).sort((item1, item2) => {
        if (item1.time_published > item2.time_published) {
            return -1;
        }
        if (item1.time_published < item2.time_published) {
            return 1;
        }

        return 0;
    });

    return (
        <div className="container">
            <div className="row">
                <div className="col col-840">
                    <div className="row" style={{ marginBottom: 21 }}>
                        <div className="col-12">
                            <div className="home-category-news-title">
                                <div className="home-category-news-title-sub">
                                    <Link to="/gallery/">{title1}</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row gx-2">
                                <div className="col-12 col-lg-8 col-xl-8 ">
                                    <div className="img-wrapper2">
                                        <AlbumView album={albumAr[0]} />
                                    </div>
                                </div>

                                <div className="col-6 col-lg-4 col-xl-4 ">
                                    <div className="img-wrapper-m img-wrapper2">
                                        <AlbumView album={albumAr[1]} />
                                    </div>
                                </div>

                                <div className="col-6 col-lg-4 col-xl-4  ">
                                    <div className="img-wrapper">
                                        <AlbumView album={albumAr[2]} />
                                    </div>
                                </div>

                                <div className="col-6 col-lg-4 col-xl-4 ">
                                    <div className="img-wrapper">
                                        <AlbumView album={albumAr[3]} />
                                    </div>
                                </div>

                                {albumAr[4] ? (
                                    <div className="col-6 col-lg-4 col-xl-4 ">
                                        <div className="img-wrapper">
                                            <AlbumView album={albumAr[4]} />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col col-300-1">
                    <SideBarNewsWithLead title={title2} articlesAr={literatureArtLeadAr} />
                </div>

                <div
                    className="col-12  d-none d-lg-block d-xl-block"
                    style={{ paddingLeft: 286, paddingRight: 286 }}
                >
                    {/* <Ads14Full show mode="placeholder" /> */}
                    <XlHome11 />
                </div>
            </div>
        </div>
    );
}

function HomeSection03({ articles1, title1, articles2, title2 }) {
    const { sizeName } = BrowserInfo();
    if (sizeName === 'bs-lg') {
        return (
            <HomeSection03MainLg
                articles1={articles1}
                title1={title1}
                articles2={articles2}
                title2={title2}
            />
        );
    }

    if (sizeName === 'bs-md') {
        return (
            <HomeSection03MainMd
                articles1={articles1}
                title1={title1}
                articles2={articles2}
                title2={title2}
            />
        );
    }

    return (
        <HomeSection03Main
            articles1={articles1}
            title1={title1}
            articles2={articles2}
            title2={title2}
        />
    );
}

export default HomeSection03;
