import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReporterIcon from '../../../../assets/images/icon.png';
import ConfigApi from '../../../../configs/ConfigApi';
import { useLastUpdate } from '../../../contexts/LastUpdateProvider';
import PageProperties from '../../../models/PageProperties';
import DetailsPlaceholder from '../../../placeholders/DetailsPlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import MultiPageLoader from '../../../utils/MultiPageLoader';
import { setActiveMenu, setLoadingStatus } from '../../../utils/SetStoreParameters';
import DefaultSidebarSection from '../section/DefaultSidebarSection';
import DetailsSection01 from '../section/DetailsSection01';
import NotFoundSection from '../section/NotFoundSection';
import NotFoundPage from './NotFoundPage';

function DetailsLoader({ sl }) {
    const dispatch = useDispatch();
    const [articleData, setArticleData] = useState(null);
    const article = articleData?.articleDetails;
    const article2 = useLastUpdate().article;

    useEffect(() => {
        dispatch(setLoadingStatus(true));
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                articleDetails: sl
            }
        })
            .then(({ data }) => {
                setArticleData(data.articles);
                dispatch(setLoadingStatus(false));
            })
            .catch(() => {
                dispatch(setLoadingStatus(false));
            });
    }, [dispatch, sl, article2]);

    if (!article) {
        return <DetailsPlaceholder />;
    }
    if (!article.sl) {
        return null;
    }
    return (
        <>
            <div className="detail-page-divider">
                <div className="first-divider">
                    <hr />
                </div>
                <div className="second-divider">
                    <div className="details-reporter-img">
                        <img
                            src={ReporterIcon}
                            alt=""
                            style={{ width: '40px', height: '40px', borderRadius: '100px' }}
                        />
                    </div>
                </div>
                <div className="third-divider">
                    <hr />
                </div>
            </div>
            <DetailsSection01 article={article} isHeaderTitle={false} />
        </>
    );
}

function DetailsControl({ article }) {
    return (
        <MultiPageLoader
            defPage={<DetailsSection01 article={article} keyName={article.sl} />}
            pages={article?.articles_this_page?.map((articleSl) => (
                <DetailsLoader sl={articleSl} keyName={articleSl} />
            ))}
        />
    );
}

function DetailPageControl() {
    const dispatch = useDispatch();
    const { articleId } = useParams();
    const [articleData, setArticleData] = useState(null);
    const article = articleData?.articleDetails || {};
    const page = article?.page || '/';

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                articleDetails: articleId
            }
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [articleId]);

    useEffect(() => {
        const pageId = page || 'na';
        if (pageId) {
            dispatch(setActiveMenu(pageId));
            PageProperties(article?.title || '');
        }
    }, [article.title, dispatch, page]);

    if (articleData === null) {
        return <DetailsPlaceholder />;
    }

    if (articleData?.articleDetails?.sl === undefined) {
        return (
            <div className="container">
                <div className="row mt-4">
                    <div className="col col-840">
                        <NotFoundPage />
                    </div>

                    <DefaultSidebarSection
                        latestArticles={articleData.recent}
                        articles1={articleData['category-online/sports']}
                        title1="খেলা"
                        articles2={articleData['category-online/entertainment']}
                        title2="বিনোদন"
                        articles3={articleData['category-online/jobs']}
                        title3="চাকরি"
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col col-840">
                    {article ? <DetailsControl article={article} /> : <NotFoundSection />}
                </div>

                <DefaultSidebarSection
                    latestArticles={articleData.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function DetailPage() {
    const { articleId } = useParams();
    return <DetailPageControl key={articleId} />;
}
