import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Iopt from '../utils/Iopt';
import PlayButton from './PlayButton';

export default function LatestAndTopNews({ latestArticles, maxArticle = 20 }) {
    const [latestShow, setLatestShow] = useState(true);

    const handleLatest = () => {
        setLatestShow(true);
    };

    return (
        <div className="updated-news pt-0">
            <div className="update-news-title">
                <button
                    type="button"
                    className={latestShow ? 'active' : ''}
                    onMouseEnter={handleLatest}
                >
                    <Link to="/news/all/">সর্বশেষ</Link>
                </button>
            </div>

            <div className={latestShow ? 'article-list update-news-body-scroll' : 'display-hide'}>
                {Object.values(latestArticles)
                    .sort((item1, item2) => {
                        if (item1.time_published > item2.time_published) {
                            return -1;
                        }
                        if (item1.time_published < item2.time_published) {
                            return 1;
                        }

                        return 0;
                    })
                    .slice(0, maxArticle)
                    .map((row) => (
                        <Link to={row.link} className="article-list-item link-area" key={row.sl}>
                            <div className="row h-100 gx-2">
                                <div className="col-4 align-self-center">
                                    <div className="image-area">
                                        <img src={Iopt(row.image, 120)} alt="im" />
                                        <PlayButton show={row.having_video} />
                                    </div>
                                </div>
                                <div className="col-8 align-self-center">
                                    <div className="text-area">
                                        {' '}
                                        {row.pre_title ? <span>{row.pre_title} / </span> : null}
                                        {row.title}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}

                <div style={{ borderTop: '1px solid #e5e5e5', overflow: 'hidden' }}>
                    <div className="link-area">
                        <Link to="/news/all/" className="category-news-footer-text link-title">
                            আরও...
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
