/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useLastUpdate } from '../../../contexts/LastUpdateProvider';
import PageProperties from '../../../models/PageProperties';
import SearchPlaceholder from '../../../placeholders/SearchPlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import { setActiveMenu } from '../../../utils/SetStoreParameters';
import DefaultSidebarSection from '../section/DefaultSidebarSection';
import SearchSection2 from '../section/SearchSection2';

const getSearchData = ({ setArticleData, page, qty }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                [page]: qty
            }
        }).then(({ data }) => {
            setArticleData(data?.articles);
        });
    });

function SearchPageControl() {
    const dispatch = useDispatch();
    const location = useLocation();
    const pageId = '/';
    const { categoryAr } = InitInfo();
    const q = new URLSearchParams(location.search).get('q');
    const page = `customSearch-${q || null}`;
    const [articleData, setArticleData] = useState(null);
    const [authorData, setAuthorData] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const { article } = useLastUpdate();
    let articleSearchData = '';

    const handleDateRange = (ev) => {
        if (ev.target.name === 'startDate') {
            setStartDate(Date.parse(ev.target.value) / 1000);
        }
        if (ev.target.name === 'endDate') {
            setEndDate(Date.parse(ev.target.value) / 1000 + 24 * 3600);
        }
    };

    if (startDate > 0 && endDate > 0) {
        articleSearchData = articleData[page]
            .filter((art) => art.time_published >= startDate)
            .filter((art) => art.time_published <= endDate);
    }
    if (startDate > 0 && !endDate) {
        articleSearchData = articleData[page].filter((art) => art.time_published > startDate);
    }

    if (endDate > 0 && startDate > 0) {
        articleSearchData = articleData[page]
            .filter((art) => art.time_published <= endDate)
            .filter((art) => art.time_published >= startDate);
    }
    if (endDate > 0 && !startDate) {
        articleSearchData = articleData[page].filter((art) => art.time_published <= endDate);
    }
    useEffect(() => {
        getSearchData({ setArticleData, page, qty: 40 });
        AxiosAuth.post(ConfigApi.API_AUTHOR).then(({ data }) => {
            setAuthorData(data.author);
        });
    }, [page]);

    useEffect(() => {
        dispatch(setActiveMenu(pageId));
    }, [dispatch]);

    PageProperties(`অনুসন্ধান: ${q}`);

    if (articleData === null) {
        return <SearchPlaceholder />;
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <SearchSection2
                    articles={articleSearchData || articleData[page] || []}
                    q={q || ''}
                    authorData={authorData || []}
                    sectionData={categoryAr?.online || []}
                    handleDateRange={handleDateRange}
                />
                <DefaultSidebarSection
                    latestArticles={articleData.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function SearchPage2() {
    const { q } = useParams();

    return <SearchPageControl key={q} />;
}
