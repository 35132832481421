import React from 'react';
import { Link } from 'react-router-dom';
import PlayButton from '../../../components/PlayButton';
import Iopt from '../../../utils/Iopt';

export default function SideBarNewsList({ title, articleAr, col = '', maxArticle = 5 }) {
    if (!articleAr) {
        return null;
    }

    const articleSls = Object.values(articleAr);

    return (
        <div>
            <div className={col || 'sidebar-category-news-title'}>
                <div className="sidebar-category-news-title-sub">
                    <Link
                        to={
                            articleSls.length > 0
                                ? `/${articleSls[0].category}/${articleSls[0].page}/`
                                : '/'
                        }
                    >
                        {title}
                    </Link>
                </div>
            </div>

            <div className="updated-news-style-2">
                <div className="updated-news-2">
                    {Object.values(articleAr)
                        // .sort((item1, item2) => {
                        //     if (item1.time_published > item2.time_published) {
                        //         return -1;
                        //     }
                        //     if (item1.time_published < item2.time_published) {
                        //         return 1;
                        //     }

                        //     return 0;
                        // })
                        .slice(0, maxArticle)
                        .map((row) => (
                            <Link
                                to={row.link || '/'}
                                className="article-list-item link-area"
                                key={row.sl}
                            >
                                <div
                                    className={['row h-100 gx-2', row.category, row.page].join(' ')}
                                >
                                    <div className="col-4 align-self-center">
                                        <div className="image-area">
                                            <img src={Iopt(row.image, 120)} alt="Im" />
                                            <PlayButton show={row.having_video} />
                                        </div>
                                    </div>
                                    <div className="col-8 align-self-center">
                                        <div className="text-area">
                                            {' '}
                                            {row.pre_title ? <span>{row.pre_title} / </span> : null}
                                            {row.title}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                </div>
                <div className="category-news-footer">
                    <div className="link-area">
                        <Link
                            to={
                                articleSls.length > 0
                                    ? `/${articleSls[0].category}/${articleSls[0].page}/`
                                    : '/'
                            }
                            className="category-news-footer-text link-title"
                        >
                            আরও...
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
