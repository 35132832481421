/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EmailIcon, EmailShareButton, FacebookIcon, XIcon } from 'react-share';
import CrossIcon from '../../assets/images/cross-icon-bar-new.svg';
import YtIcon from '../../assets/images/yt.svg';
import InitInfo from '../utils/InitInfo';

const SocialMediaLink = ({ fb, tt, yt }) => (
    <ul>
        <li>
            <a href={fb} target="_blank" rel="noreferrer">
                <FacebookIcon size={32} />
            </a>
        </li>
        <li>
            <a href={tt} target="_blank" rel="noreferrer">
                <XIcon size={32} />
            </a>
        </li>
        <li>
            <a href={yt} target="_blank" rel="noreferrer">
                <img src={YtIcon} width={32} alt="Yt Icon" />
            </a>
        </li>
        <li>
            <EmailShareButton
                url="https://bahannonews.com/"
                className="Demo__some-network__share-button"
            >
                <EmailIcon size={32} />
            </EmailShareButton>
        </li>
    </ul>
);

export default function SidebarMenuDesktop({ show, handleClose }) {
    const { configs, categoryAr } = InitInfo();
    // const ref = useRef();

    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const tDay = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
        date < 10 ? `0${date}` : `${date}`
    }`;
    // const handleClickOutside = (ev) => {
    //     if (!(ref && ref.current?.contains(ev.target))) {
    //         handleClose();
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [handleClickOutside]);
    return (
        <Modal
            // className="modal left"
            // show={show}
            // onHide={handleClose}
            // backdrop="static"
            // keyboard={false}
            // dialogClassName="modal-dialog modal-sm"
            // animation={false}
            keyboard={false}
            show={show}
            onHide={handleClose}
            dialogClassName="modal-90w sidebar-custom-width modal-dialog-slideout"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
            className="modal left sidebar-desktop-modal"
        >
            <div className="modal-content">
                <Modal.Header className="sidebar-desktop-cross">
                    <div className="sidebar-desktop-cross">
                        <img
                            src={CrossIcon}
                            alt="More Icon"
                            onClick={handleClose}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <ul className="mobile-menu">
                        <li className="link-area" key={103}>
                            <Link to="/" className="link-title" onClick={handleClose}>
                                প্রচ্ছদ
                            </Link>
                        </li>
                        <li className="link-area" key={101} onClick={handleClose}>
                            <Link to="/news/all/" className="link-title">
                                সর্বশেষ
                            </Link>
                        </li>
                        {categoryAr?.online?.map((rows) => (
                            <li key={rows.sl} className="link-area">
                                <Link to={rows.url} className="link-title" onClick={handleClose}>
                                    {rows.title}
                                </Link>
                            </li>
                        ))}

                        <li className="link-area" key={104}>
                            <Link
                                to={`/archive/${tDay}`}
                                className="link-title"
                                onClick={handleClose}
                            >
                                আর্কাইভ
                            </Link>
                        </li>
                        <li className="link-area" key={105}>
                            <a
                                href="https://converter.bahannonews.com/"
                                className="link-title"
                                target="_blank"
                                rel="noreferrer"
                                onClick={handleClose}
                            >
                                বাংলা কনভার্টার
                            </a>
                        </li>
                    </ul>
                    <div className="sidebar-share">
                        <h5>আমাদের অনুসরণ করুন</h5>
                        <SocialMediaLink
                            fb={configs.facebook}
                            tt={configs.twitter}
                            yt={configs.youtube}
                        />
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}
