/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';
import { Link } from 'react-router-dom';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappIcon,
    // eslint-disable-next-line prettier/prettier
    WhatsappShareButton,
    XIcon
} from 'react-share';
import { Tooltip } from 'react-tooltip';
import copyIcon from '../../../../assets/images/copy-solid.svg';
import FooterEditPen from '../../../../assets/images/footer-edit-pen.svg';
import ReporterIcon from '../../../../assets/images/icon.png';
import ConfigApi from '../../../../configs/ConfigApi';
import {
    Ads06,
    Ads08,
    Ads088,
    Ads09,
    Ads099,
    AdsGoogle,
    AdsYoutube
} from '../../../components/Ads';
import { useAuth } from '../../../contexts/AuthProvider';
import CommentProvider from '../../../contexts/CommentProvider';
import LightBoxProvider from '../../../contexts/LightBoxProvider';
import PickCategoryByPageId from '../../../models/PickCategoryByPageId';
import TimestampToDate from '../../../models/TimestampToDate';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import CommentSection from './CommentSection';
import DetailsSection02 from './DetailsSection02';

const createScrollStopListener = (element, callback, timeout) => {
    let removed = false;
    let handle = null;
    const onScroll = () => {
        if (handle) {
            clearTimeout(handle);
        }
        handle = setTimeout(callback, timeout || 200); // default 200 ms
    };
    element.addEventListener('scroll', onScroll);
    return () => {
        if (removed) {
            return;
        }
        removed = true;
        if (handle) {
            clearTimeout(handle);
        }
        element.removeEventListener('scroll', onScroll);
    };
};

const useScrollStopListener = (element, callback) => {
    const callbackRef = useRef();
    callbackRef.current = callback;
    useEffect(() => {
        const destroyListener = createScrollStopListener(element, () => {
            if (callbackRef.current) {
                callbackRef.current();
            }
        });
        return () => destroyListener();
    }, [element]);
};

const CatLink = ({ category, pageId }) => {
    const cat = PickCategoryByPageId(category, pageId);
    let tBg = 'home-category-news-title';
    if (
        pageId === 'education' ||
        pageId === 'bangladesh' ||
        pageId === 'international' ||
        pageId === 'expatriation' ||
        pageId === 'technology' ||
        pageId === 'lifestyle' ||
        pageId === 'health'
    ) {
        tBg = 'home-category-news-title-green';
    }
    return (
        <div className={tBg}>
            <div className="home-category-news-title-sub">
                <Link to={cat.url || '/'}>{cat.title}</Link>
            </div>
        </div>
    );
};

function ShareDetailBox({ articleLink, article, myRef, show, setShow }) {
    const { configs } = InitInfo();
    const articleSortLink = `${configs.frontUrl}/sl/${article.sl}/`;
    const [tooltip, setTooltip] = useState('Copy Link');

    const handleCopy = () => {
        navigator.clipboard.writeText(articleLink);
        setTooltip('লিঙ্ক কপি হয়েছে');
        Tooltip.rebuild();
    };

    const handleLinkClick = () => {
        setShow(false);
    };

    return (
        <div ref={myRef} className="details-share-box" style={{ display: show ? 'block' : 'none' }}>
            <table className="details-share-box-table">
                <tbody>
                    <tr className="details-share-box-tr mb-4">
                        <td width="50%">
                            <FacebookShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <FacebookIcon size={32} round />
                                <span className="share-text fb-share-hover">
                                    ফেসবুকে শেয়ার করুন
                                </span>
                            </FacebookShareButton>
                        </td>
                        <td>
                            <FacebookMessengerShareButton
                                appId={configs.fbAppId}
                                url={articleSortLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <FacebookMessengerIcon size={32} round />
                                <span className="share-text me-share-hover">
                                    ম্যাসেঞ্জারে শেয়ার করুন
                                </span>
                            </FacebookMessengerShareButton>
                        </td>
                    </tr>
                    <tr className="details-share-box-tr">
                        <td>
                            <PinterestShareButton
                                url={articleLink}
                                media={article.image}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <PinterestIcon size={32} round />
                                <span className="share-text pi-share-hover">
                                    পিন্টারিস্টে শেয়ার করুন
                                </span>
                            </PinterestShareButton>
                        </td>
                        <td>
                            <WhatsappShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <WhatsappIcon size={32} round />
                                <span className="share-text wh-share-hover">
                                    ওয়াটসঅ্যাপে শেয়ার করুন
                                </span>
                            </WhatsappShareButton>
                        </td>
                    </tr>
                    <tr className="details-share-box-tr">
                        <td style={{ position: 'relative' }}>
                            <TwitterShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <XIcon size={32} round />
                                <span className="share-text tw-share-hover">
                                    এক্স এ শেয়ার করুন
                                    <br />
                                    <small
                                        style={{
                                            position: 'absolute',
                                            fontSize: 13,
                                            top: 27,
                                            left: 36
                                        }}
                                    >
                                        (সাবেক টুইটার)
                                    </small>
                                </span>
                            </TwitterShareButton>
                        </td>
                        <td>
                            <LinkedinShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <LinkedinIcon size={32} round />
                                <span className="share-text li-share-hover">
                                    লিংকডইনে শেয়ার করুন
                                </span>
                            </LinkedinShareButton>
                        </td>
                    </tr>
                    <tr className="details-share-box-tr">
                        <td>
                            <EmailShareButton
                                openShareDialogOnClick
                                onClick={handleLinkClick}
                                url={articleLink}
                                className="Demo__some-network__share-button"
                                onMouseMove={() => setTooltip('ইমেইল করুন')}
                                data-for="copyTip"
                            >
                                <EmailIcon size={32} round />
                                <span className="share-text em-share-hover">ইমেইল করুন</span>
                            </EmailShareButton>
                        </td>
                        <td>
                            <span
                                role="button"
                                tabIndex="-1"
                                data-for="copyTip"
                                onMouseMove={() => setTooltip('লিঙ্ক কপি করুন')}
                                data-tip
                                style={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%',
                                    padding: 0
                                }}
                                onClick={handleCopy}
                            >
                                <Button className="button-copy">
                                    <img
                                        src={copyIcon}
                                        alt="Copy Icon"
                                        style={{ width: 20, height: 18 }}
                                    />
                                </Button>
                                <span className="share-text co-share-hover">লিংক কপি করুন</span>
                            </span>
                            <Tooltip id="copyTip" place="top" effect="solid">
                                {tooltip}
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

function SocialShare({ articleLink, article, configs }) {
    const [moreShareShow, setMoreShareShow] = useState(false);
    const [isSave, setIsSave] = useState(article.isSave);
    const [tooltip2, setTooltip2] = useState('কপি');
    const { currentUser } = useAuth();
    const handleMoreShare = () => {
        setMoreShareShow(true);
    };

    const ref = useRef();
    const handleClickOutside = (ev) => {
        if (!(ref && ref.current?.contains(ev.target))) {
            // alert('You clicked outside of me!');
            setMoreShareShow(false);
        }
    };
    const handleClickSave = () => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(`${ConfigApi.API_SAVE}`, { articleSl: article.sl })
            .then(() => {
                setIsSave(1);
            });
    };
    const handleLinkClick = () => {
        setMoreShareShow(false);
    };
    const handleBodyCopy = () => {
        const regex = /(<([^>]+)>)/gi;

        let newString = article?.details.replace(regex, '\n');

        newString = `${article?.pre_title}\n${article?.title}\n${article?.sub_title}\n${article?.author}\n${newString}`;

        newString = newString
            .split('&nbsp;')
            .filter((i) => !!i)
            .join(' ');

        newString = newString
            .split('\n')
            .filter((i) => i.split(' ').filter((j) => !!j).length > 0)
            .join('\n\n');

        navigator.clipboard.writeText(newString);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        setIsSave(article.isSave);
    }, [article.isSave]);

    return (
        <>
            <ul>
                <li>
                    <FacebookShareButton url={articleLink} className="details-share-fb" />
                </li>
                <li>
                    <WhatsappShareButton
                        url={articleLink}
                        className="details-share-wh"
                        onClick={handleLinkClick}
                    />
                </li>
                <li>
                    <Button
                        tabIndex="-1"
                        data-for="copyTip2"
                        data-tip
                        className="details-share-body-copy"
                        onClick={handleBodyCopy}
                    />
                    <Tooltip id="copyTip2" place="top" effect="solid">
                        {tooltip2}
                    </Tooltip>
                </li>
                {/* <li>
                    <TwitterShareButton url={articleLink} className="details-share-tw" />
                </li> */}

                <li>
                    <Button className="details-share-more" onClick={handleMoreShare} />
                </li>
                <li>
                    <Button
                        className={
                            isSave === 1 ? 'details-share-save-active' : 'details-share-save'
                        }
                        onClick={() => handleClickSave()}
                    />
                </li>
                <li>
                    <Link
                        className="details-share-printer"
                        to={`${article.link}print/`}
                        onClick={(ev) => {
                            ev.preventDefault();
                            window.print();
                        }}
                        target="_blank"
                    />
                </li>
            </ul>
            <ShareDetailBox
                articleLink={articleLink}
                show={moreShareShow}
                setShow={setMoreShareShow}
                myRef={ref}
                configs={configs}
                article={article}
            />
        </>
    );
}

const ArticleInformationAndShare = ({ article, configs, articleDet }) => {
    const articleLink = `${configs.frontUrl}/details/article/${article.sl}/`;

    const AuthorImage = articleDet?.author_image
        ? configs.frontUrl + articleDet?.author_image
        : ReporterIcon;

    return (
        <div className="row gx-1 gx-lg-2 details-reporter-info">
            <div className="col-auto">
                <div className="details-reporter-img">
                    <img
                        src={AuthorImage}
                        style={{ width: 40, height: 40, borderRadius: 100 }}
                        alt=""
                    />
                </div>
            </div>

            <div className="col">
                <div className="details-reporter-type">
                    <img src={FooterEditPen} alt="" className="mr-3" />

                    {article.author}
                </div>
                <hr className="details-reporter-divider" />
                <div className="details-report-time">
                    প্রকাশ : <TimestampToDate time={article.time_published} />
                </div>
            </div>

            <div className="col-12 col-md-auto">
                <div className="details-social">
                    <SocialShare article={article} articleLink={articleLink} configs={configs} />
                </div>
            </div>
        </div>
    );
};

const LoadScripts = ({ details }) => {
    const regex = /<script.*?src="(.*?)"/gm;

    let x;
    // eslint-disable-next-line no-cond-assign
    while ((x = regex.exec(details.split('\n').join(' '))) !== null) {
        const url = x[1];
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
    }

    return null;
};

const mixAds = (details, page) => {
    const detailsAr = details.split('</p><p>');
    const detailsFig = details.split('</figure><figure>');

    const ads08 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-12 d-lg-none d-xl-none d-md-none">
                <Ads08 show />
            </div>
        </div>
    );
    const adsSocial = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-12 col-lg-6 col-md-6 col-xl-6">
                <AdsYoutube show />
            </div>
            <div className="col-12 col-lg-6 col-md-6 col-xl-6">
                <AdsGoogle show />
            </div>
        </div>
    );

    const ads088 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-12 d-lg-none d-xl-none d-md-none ">
                <Ads099 show />
            </div>
        </div>
    );

    const ads0888 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-12 d-lg-none d-xl-none d-md-none ">
                <Ads09 show />
            </div>
        </div>
    );

    const ads0800 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-12 d-lg-none d-xl-none d-md-none ">
                <Ads088 show />
            </div>
        </div>
    );
    // detailsAr.splice(1, 0, page === 'education' ? ads0800 : ads0800);

    const ads09 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-lg-4 col-xl-4 d-none d-lg-block d-xl-block d-md-block">
                <Ads08 show />
            </div>
        </div>
    );

    const ads0999 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-lg-4 col-xl-4 d-none d-lg-block d-xl-block d-md-block">
                <Ads09 show />
            </div>
        </div>
    );

    const ads099 = ReactDOMServer.renderToStaticMarkup(
        <div className="row justify-content-md-center">
            <div className="col-lg-4 col-xl-4 d-none d-lg-block d-xl-block d-md-block">
                <Ads099 show />
            </div>
        </div>
    );

    detailsAr.splice(2, 0, ads088);

    // detailsAr.splice(5, 0, ads0888);

    detailsAr.splice(7, 0, ads0800);

    detailsAr.splice(9, 0, ads08);

    detailsAr.splice(9, 0, ads09);

    // detailsAr.splice(6, 0, ads0999);

    detailsAr.splice(3, 0, page === 'education' ? ads099 : ads099);

    detailsAr.splice(1, 0, adsSocial);

    return detailsAr.join('</p><p>');
};

const ArticleBody = ({ article, page, configs, articleLink2 }) => {
    const ref = useRef(null);
    const { details } = article;
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });

    useScrollStopListener(window, () => {
        if (visible2) {
            setVisible(true);
        }
    });
    const handleMouseLeave = () => {
        setVisible2(false);
        setVisible(false);
    };
    const handleMouseMove = (e) => {
        if (!visible) {
            setCursorPosition({ top: e.nativeEvent.layerY, left: e.nativeEvent.layerX });
        }
    };
    useEffect(() => {
        if (ref?.current && details) {
            const links = ref.current.querySelectorAll('a');
            for (let x = 0; x < links.length; x += 1) {
                links[x].setAttribute('target', '_blank');
            }
        }
    }, [details]);

    return (
        <>
            <div
                className="details-news-body mb-4"
                onMouseLeave={handleMouseLeave}
                onMouseEnter={() => setVisible2(true)}
                onMouseMove={handleMouseMove}
                style={{ position: 'relative' }}
            >
                {article.details ? (
                    <LightBoxProvider>
                        <div className="details-news-body-detail">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: mixAds(
                                        details?.replace('<a href', '<a target="_blank" href'),
                                        page
                                    )
                                }}
                            />
                        </div>
                        <LoadScripts details={details} />
                    </LightBoxProvider>
                ) : (
                    <div className="loading-news">
                        <div className="load-wraper">
                            <div className="activity" />
                        </div>
                    </div>
                )}
                {/* {visible ? (
                    <div
                        className="details-social"
                        style={{ background: 'red', position: 'absolute', ...cursorPosition }}
                    >
                        <SocialShare
                            article={article}
                            articleLink={articleLink2}
                            configs={configs}
                        />
                    </div>
                ) : null} */}
            </div>
        </>
    );
};
// const ArticleTags = ({ rTag }) => {
//     const navigate = useNavigate();

//     const findHash = rTag.split('')[0];
//     const handleTagClick = () => {
//         navigate(`/tag/?q=${encodeURIComponent(rTag)}`);
//     };
//     if (rTag !== '' || rTag !== '#') {
//         return (
//             <li key={rTag}>
//                 <button type="button" onClick={handleTagClick}>
//                     {findHash === '#' ? '' : '#'}
//                     {rTag}
//                 </button>
//             </li>
//         );
//     }
//     return null;
// };
function DetailsSection01({ article = null, isHeaderTitle = true }) {
    const { configs } = InitInfo();
    const articleDet = article.details;
    // const articleLink = `${configs.frontUrl}${article.link}`;
    const { currentUser } = useAuth();
    const relatedArticle = article.articles_related;
    const articleLink2 = `${configs.frontUrl}/details/article/${article.sl}/`;

    // const tagData = article?.tags?.split(' ');
    return (
        <>
            <div className="header-news">
                <div className="hero-news-detail">
                    {isHeaderTitle ? (
                        <CatLink category={article.category} pageId={article.page} />
                    ) : null}

                    {article.pre_title ? (
                        <h3 className="details-pre-title">{article.pre_title}</h3>
                    ) : null}

                    <h1 className="details-title">{article.lt ? article.lt : article.title}</h1>

                    {article.sub_title ? (
                        <h3 className="details-sub-title">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: article.sub_title
                                }}
                            />
                        </h3>
                    ) : null}

                    <ArticleInformationAndShare
                        article={article}
                        configs={configs}
                        articleDet={articleDet}
                    />

                    <ArticleBody
                        article={{ ...article, ...articleDet }}
                        page={article?.page}
                        configs={configs}
                        articleLink2={articleLink2}
                    />

                    <div className="row gx-1 gx-lg-2">
                        {/* <div className="col">
                            <div className="details-news-tag" style={{ marginTop: 15 }}>
                                <ul>
                                    {tagData?.map((rTag) => (
                                        <ArticleTags rTag={rTag} key={rTag} />
                                    ))}
                                </ul>
                            </div>
                        </div> */}
                        {/* <div className="col-12">
                            <div className="row">
                                <div className="col" />
                                <div className="col-12 col-lg-4">
                                    <Ads09 show />
                                </div>
                                <div className="col" />
                            </div>
                        </div> */}
                        <div className="col" />

                        <div className="col-12 col-md-auto">
                            <div className="details-social">
                                <SocialShare
                                    article={article}
                                    articleLink={articleLink2}
                                    configs={configs}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <CommentProvider id={article.sl} currentUser={currentUser}>
                            <CommentSection />
                        </CommentProvider>
                    </div>
                </div>

                <div className="print-footer" style={{ paddingLeft: '30%' }}>
                    <div
                        className="details-reporter-img"
                        style={{ display: 'flex', width: '200px' }}
                    >
                        <img
                            src={ReporterIcon}
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: 100,
                                marginTop: 25,
                                marginRight: 10
                            }}
                            alt=""
                        />
                        <h3 style={{ marginTop: 20 }}>bahannonews.com</h3>
                        <br />
                    </div>

                    <div
                        className="footer-full-editor"
                        style={{ float: 'left', marginBottom: 20, marginLeft: 7 }}
                    >
                        সম্পাদক ও প্রকাশক: বিভাষ বাড়ৈ
                    </div>
                    <br />
                </div>
                <div className="row">
                    <div className="col" />
                    <div className="col-12 d-lg-none d-xl-none">
                        <Ads06 show />
                    </div>
                    <div className="col" />
                </div>
            </div>
            <DetailsSection02 article={article} speArticleAr={relatedArticle} />
        </>
    );
}

export default DetailsSection01;
