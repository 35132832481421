/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { EmailIcon, EmailShareButton, FacebookIcon, XIcon } from 'react-share';
import SearchIcon from '../../assets/images/Search.svg';
import YtIcon from '../../assets/images/yt.svg';
import InitInfo from '../utils/InitInfo';
import TimeShow from '../utils/TimeShow';

const SocialMediaLink = ({ fb, tt, yt }) => (
    <ul>
        <li>
            <a href={fb} target="_blank" rel="noreferrer">
                <FacebookIcon size={32} />
            </a>
        </li>
        <li>
            <a href={tt} target="_blank" rel="noreferrer">
                <XIcon size={32} />
            </a>
        </li>
        <li>
            <a href={yt} target="_blank" rel="noreferrer">
                <img src={YtIcon} width={32} alt="Yt Icon" />
            </a>
        </li>
        <li>
            <EmailShareButton
                url="https://bahannonews.com/"
                className="Demo__some-network__share-button"
            >
                <EmailIcon size={32} />
            </EmailShareButton>
        </li>
    </ul>
);

export default function SidebarMenuMobile({ show, handleClose }) {
    const { initData, configs, categoryAr } = InitInfo();

    const inp = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const q = new URLSearchParams(location.search).get('q');
    const [searchKey, setSearchKey] = useState(q);

    const onSearchClick = (ev, mode) => {
        const moveToSearch = () => {
            handleClose();
            navigate(`/search/?q=${encodeURIComponent(searchKey)}`);
        };

        if (mode === 'change') {
            setSearchKey(ev.target.value);
        }

        if (mode === 'keyup') {
            if (ev.keyCode === 13) {
                // On press enter
                moveToSearch();
            } else {
                setSearchKey(ev.target.value);
            }
        } else if (mode === 'searchClick') {
            moveToSearch();
        }
    };
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const tDay = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
        date < 10 ? `0${date}` : `${date}`
    }`;
    return (
        <Modal
            // className="modal left"
            // show={show}
            // onHide={handleClose}
            // backdrop="static"
            // keyboard={false}
            // dialogClassName="modal-dialog modal-sm"
            // animation={false}
            show={show}
            onHide={handleClose}
            dialogClassName="modal-90w sidebar-menu modal-dialog-slideout"
            aria-labelledby="example-custom-modal-styling-title "
            size="xl"
            className="modal left "
        >
            <div className="modal-content">
                <Modal.Body className="modal-body">
                    <div className="sidebar-menu-time">
                        {initData.currentTime ? <TimeShow time={initData.currentTime} /> : null}
                    </div>

                    <div className="header-search-group header-search-group-side mb-2">
                        <div className="header-search-text-input header-search-text-input-side">
                            <input
                                ref={inp}
                                placeholder="অনুসন্ধান করুন..."
                                onKeyUp={(ev) => {
                                    onSearchClick(ev, 'keyup');
                                }}
                                value={searchKey || ''}
                                onChange={(ev) => {
                                    onSearchClick(ev, 'change');
                                }}
                            />
                        </div>
                        <button
                            type="button"
                            className="header-search-send"
                            onClick={(ev) => {
                                onSearchClick(ev, 'searchClick');
                            }}
                        >
                            <img src={SearchIcon} alt="Send Icon" />
                        </button>
                    </div>
                    <div className="row mb-3" style={{ paddingLeft: '7px' }}>
                        <div className="col-6">
                            <ul className="sidebar-menu-ul">
                                <li className="link-area" key={101} onClick={handleClose}>
                                    <Link to="/news/all/" className="link-title">
                                        সর্বশেষ{' '}
                                    </Link>
                                </li>
                                {categoryAr?.online?.slice(0, 9)?.map((rows) => (
                                    <li key={rows.sl} className="link-area">
                                        <Link
                                            to={rows.url}
                                            className="link-title"
                                            onClick={handleClose}
                                        >
                                            {rows.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-6">
                            <ul className="sidebar-menu-ul">
                                {categoryAr?.online?.slice(9, 20)?.map((rows) => (
                                    <li key={rows.sl} className="link-area">
                                        <Link
                                            to={rows.url}
                                            className="link-title"
                                            onClick={handleClose}
                                        >
                                            {rows.title}
                                        </Link>
                                    </li>
                                ))}
                                <li className="link-area" key={104}>
                                    <Link
                                        to={`/archive/${tDay}`}
                                        className="link-title"
                                        onClick={handleClose}
                                    >
                                        আর্কাইভ
                                    </Link>
                                </li>
                                <li className="link-area" key={105}>
                                    <a
                                        href="https://converter.bahannonews.com/"
                                        className="link-title"
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={handleClose}
                                    >
                                        বাংলা কনভার্টার
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="sidebar-share">
                        <h5>আমাদের অনুসরণ করুন</h5>
                        <SocialMediaLink
                            fb={configs.facebook}
                            tt={configs.twitter}
                            yt={configs.youtube}
                        />
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}
