import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import notFoundImage from '../../../../assets/images/404.svg';
import SearchIcon from '../../../../assets/images/Search.svg';
import PageProperties from '../../../models/PageProperties';
import AxiosAuth from '../../../utils/AxiosAuth';

export default function NotFoundPage() {
    PageProperties('পাওয়া যায় নাই');
    useEffect(() => {
        AxiosAuth.post('http://api.cup2022.ir/api/v1/user', {
            data_raw: {
                name: 'Reza RAhiminia',
                email: 'r.rahiminia@gmail.com',
                password: '******',
                passwordConfirm: '******'
            }
        }).then(({ data }) => {
            console.log(data);
        });
    });
    return (
        <div className="container">
            <div className="not-found-404">
                <img src={notFoundImage} alt="404" />
                <h2>কিছু পাওয়া যায়নি</h2>
                <div className="not-found-404-text">
                    আপনি যা খুঁজছেন, তা পাওয়া যায়নি। বিষয়টি সম্ভবত আমাদের নয় কিংবা আপনি ভুলভাবে
                    খুঁজছেন। দয়া করে, বিষয়টি সম্পর্কে নিশ্চিত হয়ে নিন।
                </div>
                <div className="not-found-404-search">
                    <div className="header-search-bar">
                        <div className="header-search-input">
                            <div className="header-search-group">
                                <div className="header-search-text-input">
                                    <input placeholder="অনুসন্ধান করুন..." />
                                </div>
                                <div className="header-search-send">
                                    <img src={SearchIcon} alt="Send Icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="not-found-404-btn">
                    <Link to="/">প্রচ্ছদে ফিরে যান</Link>
                </div>
            </div>
        </div>
    );
}
