import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CalNextDouble from '../../../../assets/images/icon-next-double.svg';
import CalNextSingle from '../../../../assets/images/icon-next-single.svg';
import CalPrevDouble from '../../../../assets/images/icon-prev-double.svg';
import CalPrevSingle from '../../../../assets/images/icon-prev-single.svg';
import ConvertStringBn from '../../../utils/ConvertStringBn';

const conf = {
    dayStart: 6,
    holiday: 5
};

const months = [
    { code: '01', name: 'January', nameSort: 'Jan', days: 31 },
    { code: '02', name: 'February', nameSort: 'Feb', days: 28 },
    { code: '03', name: 'March', nameSort: 'Mar', days: 31 },
    { code: '04', name: 'April', nameSort: 'Apr', days: 30 },
    { code: '05', name: 'May', nameSort: 'May', days: 31 },
    { code: '06', name: 'June', nameSort: 'Jun', days: 30 },
    { code: '07', name: 'July', nameSort: 'Jul', days: 31 },
    { code: '08', name: 'August', nameSort: 'Aug', days: 31 },
    { code: '09', name: 'September', nameSort: 'Sep', days: 30 },
    { code: '10', name: 'October', nameSort: 'Oct', days: 31 },
    { code: '11', name: 'November', nameSort: 'Dec', days: 30 },
    { code: '12', name: 'December', nameSort: 'Jan', days: 31 }
];

const dayNames = [
    { index: 0, name: 'Sunday', sortName: 'Sun' },
    { index: 1, name: 'Monday', sortName: 'Mon' },
    { index: 2, name: 'Tuesday', sortName: 'Tue' },
    { index: 3, name: 'Wednesday', sortName: 'Wed' },
    { index: 4, name: 'Thursday', sortName: 'Thu' },
    { index: 5, name: 'Friday', sortName: 'Fri' },
    { index: 6, name: 'Saturday', sortName: 'Sat' }
];

const weeks = [0, 1, 2, 3, 4, 5, 6];

const Calender = ({
    year,
    month,
    day,
    onDayClick,
    onNextSingle,
    onPrevSingle,
    onNextDouble,
    onPrevDouble
}) => {
    const newTime = new Date(year, month - 1, 1);
    const dayStart = newTime.getDay();
    const monthInfo = months.find((item) => parseInt(item.code, 10) === month) || {};

    if (Object.keys(monthInfo).length > 0 && year % 4 === 0 && monthInfo.code === '02') {
        monthInfo.days = 29;
    } else if (monthInfo.code === '02') {
        monthInfo.days = 28;
    }

    const dayNamesMap = [];
    const dayNamesLength = dayNames.length;
    for (let i = conf.dayStart; i < conf.dayStart + dayNamesLength; i += 1) {
        const j = i % dayNamesLength;
        dayNamesMap.push(dayNames[j]);
    }

    const todayTime = new Date();
    const dateToday = todayTime.getDate();

    return (
        <div className="sidebar-calendar">
            <div className="update-news-title" style={{ marginBottom: 5 }}>
                <button type="button" className="active">
                    আর্কাইভ
                </button>
                {/* <button type="button" className={topShow ? 'active' : ''} onMouseEnter={handleTop}>
                    জনপ্রিয়
                </button> */}
            </div>
            <table width="100%" style={{ textAlign: 'center' }}>
                <thead>
                    <tr className="sidebar-calendar-month">
                        <td colSpan="7">
                            <a
                                href="/"
                                onClick={(ev) => {
                                    onPrevDouble(ev, year, month, dateToday);
                                }}
                                style={{ float: 'left', paddingLeft: 10 }}
                            >
                                <img src={CalPrevDouble} alt="Cal Prev Double" />
                            </a>

                            <a
                                href="/"
                                onClick={(ev) => {
                                    onPrevSingle(ev, year, month, dateToday);
                                }}
                                style={{ float: 'left', paddingLeft: 10 }}
                            >
                                <img src={CalPrevSingle} alt="Cal Prev Single" />
                            </a>

                            <span className="sidebar-calendar-month-text">
                                {ConvertStringBn(`${monthInfo.name}, ${year}`)}
                            </span>
                            <a
                                href="/"
                                onClick={(ev) => {
                                    onNextDouble(ev, year, month, dateToday);
                                }}
                                style={{ float: 'right', paddingRight: 10 }}
                            >
                                <img src={CalNextDouble} alt="Cal Next Double" />
                            </a>

                            <a
                                href="/"
                                onClick={(ev) => {
                                    onNextSingle(ev, year, month, dateToday);
                                }}
                                style={{ float: 'right', paddingRight: 10 }}
                            >
                                <img src={CalNextSingle} alt="Cal Next Single" />
                            </a>
                        </td>
                    </tr>
                    <tr className="sidebar-calendar-weekdays">
                        {dayNamesMap.map((item) => {
                            const clsName = [];
                            if (item.index === conf.holiday) {
                                clsName.push('holiday');
                            }

                            return (
                                <td className={clsName.join(' ')} key={item.sortName}>
                                    <span className="sidebar-calendar-weekdays-text">
                                        {ConvertStringBn(item.sortName)}
                                    </span>
                                </td>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {weeks.map((wIndex) => (
                        <tr key={weeks[wIndex]}>
                            {dayNamesMap.map((item, i) => {
                                const x = item.index > i ? item.index - dayNamesLength : item.index;
                                const date = wIndex * dayNames.length + x - dayStart + 1;

                                if (date > monthInfo.days) {
                                    return <td key={date}> </td>;
                                }

                                const clsName = [];
                                if (date === day) {
                                    clsName.push('selected');
                                }

                                if (date === dateToday) {
                                    clsName.push('today');
                                }

                                if (item.index === conf.holiday) {
                                    clsName.push('holiday');
                                }

                                return (
                                    <td className={clsName.join(' ')} key={date}>
                                        <a
                                            href="/"
                                            onClick={(ev) => {
                                                onDayClick(ev, year, month, date);
                                            }}
                                            className="sidebar-calendar-days-text"
                                        >
                                            {date > 0 ? ConvertStringBn(date.toString()) : ' '}
                                        </a>
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default function SidebarCalendar() {
    const navigate = useNavigate();

    const todayTime = new Date();
    const dateToday = todayTime.getDate();
    const monthToday = todayTime.getMonth() + 1;
    const yearToday = todayTime.getFullYear();
    const [ymd, setYmd] = useState(`${yearToday}-${monthToday}-${dateToday}`);
    const ymdData = ymd.split('-');
    const year = parseInt(ymdData[0], 10);
    const month = parseInt(ymdData[1], 10);
    const day = parseInt(ymdData[2], 10);

    const onDayClick = (ev, yearX, monthX, dateX) => {
        ev.preventDefault();
        navigate(
            `/archive/${yearX.toString().padStart(2, '0')}-${monthX
                .toString()
                .padStart(2, '0')}-${dateX.toString().padStart(2, '0')}/`
        );
    };

    const onNextSingle = (ev) => {
        ev.preventDefault();

        const y = month >= 12 ? 1 : 0;
        const m = month >= 12 ? -11 : 1;
        setYmd(`${year + y}-${month + m}-${day}`);
    };
    const onPrevSingle = (ev) => {
        ev.preventDefault();

        const y = month <= 1 ? 1 : 0;
        const m = month <= 1 ? -11 : 1;
        setYmd(`${year - y}-${month - m}-${day}`);
    };
    const onNextDouble = (ev, yearX, monthX, dateX) => {
        ev.preventDefault();
        setYmd(`${yearX + 1}-${monthX}-${dateX}`);
    };
    const onPrevDouble = (ev, yearX, monthX, dateX) => {
        ev.preventDefault();
        setYmd(`${yearX - 1}-${monthX}-${dateX}`);
    };

    return (
        <Calender
            key={ymd}
            year={year}
            month={month}
            day={day}
            onDayClick={onDayClick}
            onNextSingle={onNextSingle}
            onPrevSingle={onPrevSingle}
            onNextDouble={onNextDouble}
            onPrevDouble={onPrevDouble}
        />
    );
}
