/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-undef */
import '../../assets/css/placeholder.css';
import DetailsPlaceholderImg2 from '../../assets/images/details-placeholder2.png';

function ArchivePagePlaceholder() {
    return (
        <div>
            {/* Body Section */}

            <div className="container">
                <div className="row mt-5">
                    <div className="col col-840">
                        <div style={{ marginBottom: '15px' }}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="sidebar-category-news-title" />
                                </div>
                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="sidebar-category-news-title" />
                                </div>
                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="sidebar-category-news-title" />
                                </div>
                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-300-1">
                        <div className="sidebar-add-one">
                            <img src={DetailsPlaceholderImg2} alt="Add One" />
                        </div>
                        <div className="sidebar-category-news">
                            <div className="sidebar-category-news-title" />
                            <div className="side-news-short">
                                <div className="side-news-short-img">
                                    <img src={DetailsPlaceholderImg2} alt="news" />
                                </div>
                                <div className="side-news-short-title">
                                    <div
                                        className="details-report-time"
                                        style={{
                                            backgroundColor: '#E8F1FE',
                                            height: 10
                                        }}
                                    />
                                    <div
                                        className="details-report-time"
                                        style={{
                                            backgroundColor: '#E8F1FE',
                                            height: 10,
                                            marginTop: 10
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArchivePagePlaceholder;
