/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import '../../assets/css/placeholder.css';
import DetailsPlaceholderImg2 from '../../assets/images/details-placeholder2.png';

function SearchPlaceholder() {
    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col col-840">
                    <div className="row">
                        <div className="col-12 col-lg-4 col-xl-4 col-md-5">
                            <h4>অনুসন্ধান</h4>
                        </div>
                        <div className="col-12 col-lg-8 col-xl-8 col-md-7">
                            <div className="search-page-input" />
                        </div>
                        <div className="col-12 search-section-devider">
                            <hr />
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4 col-md-5">
                            <div className="search-page-sidebar">
                                <div className="search-page-sidebar-title">প্রাপ্ত ফলাফল:</div>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>লেখক</Accordion.Header>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>সেকশন</Accordion.Header>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>ধরন</Accordion.Header>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>সাজানো</Accordion.Header>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>তারিখ</Accordion.Header>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 col-xl-8 col-md-7">
                            <div className="search-section-box link-area">
                                <div className="search-section-box-info link-title align-self-center">
                                    <h4
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '25px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <p
                                        className="category-news-f-time float-right"
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '60%',
                                            height: '15px'
                                        }}
                                    />
                                </div>
                                <div
                                    className="search-section-box-img"
                                    style={{
                                        backgroundColor: '#F7F9FD',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            <div className="search-section-box link-area">
                                <div className="search-section-box-info link-title align-self-center">
                                    <h4
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '25px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <p
                                        className="category-news-f-time float-right"
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '60%',
                                            height: '15px'
                                        }}
                                    />
                                </div>
                                <div
                                    className="search-section-box-img"
                                    style={{
                                        backgroundColor: '#F7F9FD',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            <div className="search-section-box link-area">
                                <div className="search-section-box-info link-title align-self-center">
                                    <h4
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '25px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <p
                                        className="category-news-f-time float-right"
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '60%',
                                            height: '15px'
                                        }}
                                    />
                                </div>
                                <div
                                    className="search-section-box-img"
                                    style={{
                                        backgroundColor: '#F7F9FD',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            <div className="search-section-box link-area">
                                <div className="search-section-box-info link-title align-self-center">
                                    <h4
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '25px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <p
                                        className="category-news-f-time float-right"
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '60%',
                                            height: '15px'
                                        }}
                                    />
                                </div>
                                <div
                                    className="search-section-box-img"
                                    style={{
                                        backgroundColor: '#F7F9FD',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            <div className="search-section-box link-area">
                                <div className="search-section-box-info link-title align-self-center">
                                    <h4
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '25px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <p
                                        className="category-news-f-time float-right"
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '60%',
                                            height: '15px'
                                        }}
                                    />
                                </div>
                                <div
                                    className="search-section-box-img"
                                    style={{
                                        backgroundColor: '#F7F9FD',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            <div className="search-section-box link-area">
                                <div className="search-section-box-info link-title align-self-center">
                                    <h4
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '25px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <p
                                        className="category-news-f-time float-right"
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '60%',
                                            height: '15px'
                                        }}
                                    />
                                </div>
                                <div
                                    className="search-section-box-img"
                                    style={{
                                        backgroundColor: '#F7F9FD',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            <div className="search-section-box link-area">
                                <div className="search-section-box-info link-title align-self-center">
                                    <h4
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '25px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <h5
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '100%',
                                            height: '20px'
                                        }}
                                    />
                                    <p
                                        className="category-news-f-time float-right"
                                        style={{
                                            backgroundColor: '#F7F9FD',
                                            width: '60%',
                                            height: '15px'
                                        }}
                                    />
                                </div>
                                <div
                                    className="search-section-box-img"
                                    style={{
                                        backgroundColor: '#F7F9FD',
                                        width: '100%'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col-300-1">
                    <div className="sidebar-add-one">
                        <img src={DetailsPlaceholderImg2} alt="Add One" />
                    </div>
                    <div className="sidebar-category-news">
                        <div className="sidebar-category-news-title" />
                        <div className="side-news-short">
                            <div className="side-news-short-img">
                                <img src={DetailsPlaceholderImg2} alt="news" />
                            </div>
                            <div className="side-news-short-title">
                                <div
                                    className="details-report-time"
                                    style={{
                                        backgroundColor: '#E8F1FE',
                                        height: 10
                                    }}
                                />
                                <div
                                    className="details-report-time"
                                    style={{
                                        backgroundColor: '#E8F1FE',
                                        height: 10,
                                        marginTop: 10
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>{' '}
            </div>
        </div>
    );
}

export default SearchPlaceholder;
