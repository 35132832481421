import React from 'react';
import { Link } from 'react-router-dom';
import { XlHome10 } from '../../../components/AdManagerXl';
import { Ads14Full } from '../../../components/Ads';
import HeroNews from '../../../components/HeroNews';
import UpdatedNewsListStyle2 from '../../../components/UpdatedNewsListStyle2';
import BrowserInfo from '../../../utils/BrowserInfo';
import SideBarNewsList from '../components/SideBarNewsList';

function HomeSection04MainMd({ articles1, title1 }) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="home-category-news-title-green">
                        <div className="home-category-news-title-sub">
                            <Link to="/online/bangladesh/">{title1}</Link>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-510">
                    <HeroNews article={articles1[0]} />
                </div>

                <div className="col-4 col-300">
                    <UpdatedNewsListStyle2
                        pageId="bangladesh"
                        articleAr={articles1}
                        maxArticle={6}
                    />
                </div>
            </div>
            <div className="col-12 d-lg-none d-block mb-3">
                <Ads14Full show mode="placeholder" />
            </div>
        </div>
    );
}

function HomeSection04MainLg({ articles1, title1 }) {
    const nationalArticleAr = articles1;
    const nationalArticleArLead = nationalArticleAr[0];

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="home-category-news-title-green">
                        <div className="home-category-news-title-sub">
                            <Link to="/online/bangladesh/">{title1}</Link>
                        </div>
                    </div>
                </div>

                <div className="col col-840">
                    <HeroNews article={nationalArticleArLead} />
                </div>

                <div className="col col-300-1">
                    <UpdatedNewsListStyle2
                        pageId="bangladesh"
                        articleAr={nationalArticleAr}
                        maxArticle={7}
                    />
                </div>
                <div className="col-12 mb-3">
                    <Ads14Full show mode="placeholder" />
                </div>
            </div>
        </div>
    );
}

function HomeSection04Main({ articles1, title1, articles2, title2 }) {
    const nationalArticleAr = articles1;
    const nationalArticleArLead = nationalArticleAr[0];

    return (
        <div className="container">
            <div className="row">
                <div className="col col-840">
                    <div className="row">
                        <div className="col-12">
                            <div className="home-category-news-title-green">
                                <div className="home-category-news-title-sub">
                                    <Link to="/online/bangladesh/">{title1}</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-510">
                            <HeroNews article={nationalArticleArLead} />
                        </div>

                        <div className="col-4 col-300">
                            <UpdatedNewsListStyle2
                                pageId="bangladesh"
                                articleAr={nationalArticleAr}
                                maxArticle={7}
                            />
                        </div>
                    </div>
                    <div className="col-12 d-lg-none d-block mb-3">
                        <Ads14Full show mode="placeholder" />
                    </div>
                </div>
                <div className="col col-300-1">
                    <SideBarNewsList
                        title={title2}
                        articleAr={articles2}
                        col="sidebar-category-news-title-green"
                        maxArticle={6}
                    />
                </div>
                <div
                    className="col-12 d-none d-lg-block d-xl-block"
                    style={{ paddingLeft: 286, paddingRight: 286 }}
                >
                    {/* <Ads14Full show mode="placeholder" /> */}
                    <XlHome10 />
                </div>
            </div>
        </div>
    );
}

function HomeSection04({ articles1, title1, articles2, title2 }) {
    const { sizeName } = BrowserInfo();

    if (sizeName === 'bs-lg') {
        return <HomeSection04MainLg title1={title1} articles1={articles1} />;
    }

    if (sizeName === 'bs-md') {
        return (
            <HomeSection04MainMd
                title1={title1}
                articles1={articles1}
                title2={title2}
                articles2={articles2}
            />
        );
    }

    return (
        <HomeSection04Main
            title1={title1}
            articles1={articles1}
            title2={title2}
            articles2={articles2}
        />
    );
}

export default HomeSection04;
