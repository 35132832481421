import HeroNews from '../../../components/HeroNews';
import LatestAndTopNews from '../../../components/LatestAndTopNews';

export default function HeroAndUpdatedNewsLg({
    heroNewsArticle,
    latestArticles,
    popularArticles,
    ads
}) {
    return (
        <div className="row">
            <div className="col-12 col-510 ">
                <HeroNews article={heroNewsArticle} />
            </div>
            <div className="col-6">
                <LatestAndTopNews
                    latestArticles={latestArticles}
                    popularArticles={popularArticles}
                    maxArticle={5}
                />
            </div>
            <div className="col-6">{ads}</div>
        </div>
    );
}
