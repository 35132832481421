import React from 'react';
import { Link } from 'react-router-dom';
import PlayButton from '../../../components/PlayButton';
import Iopt from '../../../utils/Iopt';

function SectionThumb({ title, articleAr }) {
    return (
        <div className="row">
            <div className="col-12">
                <div className="row hero-news-short-play-row">
                    <div className="col-12">
                        <div className="hero-news-short-play-top-title">
                            <div className="hero-news-short-play-top-title-sub">
                                <Link to={`online/${articleAr[0].page}`}>{title}</Link>
                            </div>
                        </div>
                    </div>
                    {Object.values(articleAr)
                        .sort((a, b) => b.time_published - a.time_published)
                        .slice(0, 6)
                        .map((row) => (
                            <Link
                                to={row.link}
                                key={row.sl}
                                className="col-12 col-md-6 col-lg-4 col-xl-4"
                            >
                                <div className="hero-news-short-play link-area">
                                    <div className="hero-news-short-play-img image-area">
                                        <img src={Iopt(row.image, 360)} alt="Play" />
                                        <PlayButton show={row.having_video} />
                                    </div>
                                    <div className="hero-news-short-play-title link-title">
                                        {row.title}
                                    </div>
                                    <div className="hero-news-short-play-detail">{row.summery}</div>
                                </div>
                            </Link>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default SectionThumb;
