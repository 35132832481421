/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import MoreIcon from '../../../../assets/images/more-icon-bar-new.svg';
import InitInfo from '../../../utils/InitInfo';

function classNameControl(defClassName, top) {
    const newClassList = [defClassName];

    if (top > 140) {
        newClassList.push();

        newClassList.push('position-higher');
    } else {
        newClassList.push('position-lower');
    }

    return newClassList;
}
export default function MobileMenuSection({ handleShow }) {
    const { categoryAr } = InitInfo();
    const headerRef = useRef();
    const headerRef2 = useRef();

    const activeMenu = useSelector((state) => state.activeMenu);
    const { pathname } = useLocation();

    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const tDay = `${year}-${month < 10 ? `0${month}` : `${month}`}-${
        date < 10 ? `0${date}` : `${date}`
    }`;

    useEffect(() => {
        if (headerRef?.current) {
            headerRef.current.className = classNameControl('header-menu-mobile', 0).join(' ');

            headerRef2.current.className = classNameControl('fake-height', 0).join(' ');

            const onScroll = (e) => {
                const topPosition = e?.target?.documentElement?.scrollTop || '';
                headerRef.current.className = classNameControl(
                    'header-menu-mobile',
                    topPosition
                ).join(' ');

                headerRef2.current.className = classNameControl('fake-height', topPosition).join(
                    ' '
                );
            };
            window.addEventListener('scroll', onScroll);

            return () => window.removeEventListener('scroll', onScroll);
        }
        return null;
    }, [pathname]);

    return (
        <>
            <div ref={headerRef2} />
            <div className="header-menu-mobile" style={{ background: '#FFFFFF' }} ref={headerRef}>
                <div className="container">
                    <div className="mobile-sort-menu-main">
                        <div className="mobile-sort-menu">
                            <ul>
                                <li className="mobile-sort-menu-fixed-li">
                                    <img
                                        src={MoreIcon}
                                        alt="More Icon"
                                        onClick={handleShow}
                                        style={{ width: ' 17px', marginRight: 5 }}
                                    />
                                    <a
                                        href="/"
                                        style={{
                                            paddingRight: 0,
                                            paddingLeft: 0,
                                            borderBottom: pathname === '/' ? '2px solid red' : '',
                                            height: ' 100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingTop: '1px'
                                        }}
                                    >
                                        প্রচ্ছদ
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="mobile-sort-menu">
                            <ul>
                                <li
                                    style={{ marginLeft: 10 }}
                                    className={pathname === '/news/all/' ? 'menu-view-active' : ''}
                                    key={101}
                                >
                                    <Link to="/news/all/" className="link-title">
                                        সর্বশেষ
                                    </Link>
                                </li>
                                {categoryAr.online &&
                                    categoryAr.online.map((item) => (
                                        <li
                                            className={
                                                activeMenu === item.page_id
                                                    ? 'menu-view-active'
                                                    : ''
                                            }
                                            key={item.page_id}
                                        >
                                            <Link to={item.url || '/'}>{item.title}</Link>
                                        </li>
                                    ))}

                                <li
                                    className={
                                        pathname === `/archive/${tDay}` ? 'menu-view-active' : ''
                                    }
                                    key={104}
                                >
                                    <Link to={`/archive/${tDay}`} className="link-title">
                                        আর্কাইভ
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
