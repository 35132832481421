import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useLastUpdate } from '../../../contexts/LastUpdateProvider';
import PageProperties from '../../../models/PageProperties';
import CategoryPlaceholder from '../../../placeholders/CategoryPlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import { setActiveMenu } from '../../../utils/SetStoreParameters';
import AllNewsSection from '../section/AllNewsSection';
import DefaultSidebarSection from '../section/DefaultSidebarSection';

function CategoryPageControl() {
    const dispatch = useDispatch();
    const [articleData, setArticleData] = useState(null);
    const articles = articleData?.recent || [];
    const { article } = useLastUpdate();

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 550,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,
                all: 20
            }
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [article]);

    useEffect(() => {
        dispatch(setActiveMenu('/'));
        PageProperties('সর্বশেষ');
    }, [dispatch]);

    if (articleData === null) {
        return <CategoryPlaceholder />;
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <AllNewsSection category="online" articles={articles} />

                <DefaultSidebarSection
                    latestArticles={articleData.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function AllNewsPage() {
    const { pageId } = useParams();

    return <CategoryPageControl key={pageId} />;
}
