import { combineReducers } from 'redux';
import ActiveMenuReducer from './reducers/ActiveMenuReducer';
import InitSetReducer from './reducers/InitSetReducer';
import LoadingStatusReducer from './reducers/LoadingStatusReducer';
import SiteSetReducer from './reducers/SiteSetReducer';

const rootReducer = combineReducers({
    initData: InitSetReducer,

    siteData: SiteSetReducer,

    activeMenu: ActiveMenuReducer,

    loadingStatus: LoadingStatusReducer
});

export default rootReducer;
