import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useLastUpdate } from '../../../contexts/LastUpdateProvider';

import PageProperties from '../../../models/PageProperties';
import IndexPagePlaceholder from '../../../placeholders/IndexPagePlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import { setActiveMenu } from '../../../utils/SetStoreParameters';
import DefaultSidebarSection from '../section/DefaultSidebarSection';

function AlbumView({ album }) {
    if (!album) {
        return null;
    }

    return (
        <>
            <div className="inner-overlay" />

            <Link className="lightbox" to={album.link}>
                <img src={album.image} alt="Traffic" className="inner-img2" />
                <span className="tz-gallery-img-title">{album.title}</span>
            </Link>
        </>
    );
}

function GalleryPage() {
    const dispatch = useDispatch();
    const [indexArticles, setIndexArticles] = useState(null);
    const { article } = useLastUpdate();

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                gallery: 20,
                recent: 5,
                'category-online/literature': 5,
                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5
            }
        }).then(({ data }) => {
            setIndexArticles(data.articles);
        });
    }, [article]);

    useEffect(() => {
        dispatch(setActiveMenu('/gallery/'));
        PageProperties('');
    }, [dispatch]);

    if (indexArticles === null) {
        return <IndexPagePlaceholder />;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col col-840">
                    <div className="row" style={{ marginBottom: 21 }}>
                        <div className="col-12">
                            <div className="home-category-news-title">
                                <div className="home-category-news-title-sub">
                                    <Link to="/gallery/">ফটো গ্যালারি</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row gx-2">
                                {indexArticles?.gallery.map((albumAr) => (
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="img-wrapper-m img-wrapper2">
                                            <AlbumView album={albumAr} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <DefaultSidebarSection
                    latestArticles={indexArticles.recent}
                    articles1={indexArticles['category-online/sports']}
                    title1="খেলা"
                    articles2={indexArticles['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={indexArticles['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default GalleryPage;
