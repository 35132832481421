/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-undef */
import '../../assets/css/placeholder.css';
import DetailsPlaceholderImg from '../../assets/images/details-placeholder.png';
import DetailsPlaceholderImg2 from '../../assets/images/details-placeholder2.png';

export default function DetailsPlaceholder() {
    return (
        <div>
            <div className="container">
                <div className="row mt-5">
                    <div className="col col-840">
                        <div className="header-news">
                            <div className="hero-news-detail">
                                <div className="home-category-news-title" />
                                <div className="details-title" />
                                <div className="row details-reporter-info">
                                    <div className="col-2 col-lg-1 col-xl-1">
                                        <div
                                            className="details-reporter-img"
                                            style={{
                                                backgroundColor: '#E8F1FE',
                                                width: 50,
                                                height: 50,
                                                borderRadius: '50%'
                                            }}
                                        />
                                    </div>
                                    <div className="col-7">
                                        <div
                                            className="details-reporter-type"
                                            style={{
                                                backgroundColor: '#E8F1FE',
                                                width: 130,
                                                height: 20
                                            }}
                                        />
                                        <hr className="details-reporter-divider" />
                                        <div
                                            className="details-report-time"
                                            style={{
                                                backgroundColor: '#E8F1FE',
                                                width: 230,
                                                height: 20
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <div className="details-social">
                                            <ul>
                                                <li className="details-share-text" />
                                                <li>
                                                    <a href="djsf" className="facebook-icon-btn" />
                                                </li>
                                                <li>
                                                    <a href="djsf" className="facebook-icon-btn" />
                                                </li>
                                                <li>
                                                    <a href="djsf" className="facebook-icon-btn" />
                                                </li>
                                                <li>
                                                    <a href="djsf" className="facebook-icon-btn" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="details-news-body mb-4">
                                    <div className="details-news-body-detail">
                                        <img src={DetailsPlaceholderImg} alt="palce" />
                                    </div>

                                    <div className="details-news-tag">
                                        <ul>
                                            <li>
                                                <a href="fd" />
                                            </li>
                                            <li>
                                                <a href="fd" />
                                            </li>
                                            <li>
                                                <a href="fd" />
                                            </li>
                                            <li>
                                                <a href="fd" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="fb-comment-box" />
                            </div>
                        </div>
                        <div style={{ margin: '15px' }}>
                            <div className="row">
                                <div className="col-12 details-realted-news-title">
                                    <div
                                        className="details-report-time"
                                        style={{
                                            backgroundColor: '#E8F1FE',
                                            width: 230,
                                            height: 40
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{' '}
                    </div>
                    <div className="col col-300-1">
                        <div className="sidebar-category-news">
                            <div className="sidebar-category-news-title" />
                            <div className="side-news-short">
                                <div className="side-news-short-img">
                                    <img src={DetailsPlaceholderImg2} alt="news" />
                                </div>
                                <div className="side-news-short-title">
                                    <div
                                        className="details-report-time"
                                        style={{
                                            backgroundColor: '#E8F1FE',
                                            height: 10
                                        }}
                                    />
                                    <div
                                        className="details-report-time"
                                        style={{
                                            backgroundColor: '#E8F1FE',
                                            height: 10,
                                            marginTop: 10
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>{' '}
                </div>
            </div>
        </div>
    );
}
