import React, { useEffect, useState } from 'react';

import ConfigApi from '../../../../configs/ConfigApi';
import PageProperties from '../../../models/PageProperties';
import AxiosAuth from '../../../utils/AxiosAuth';
import ConvertStringBn from '../../../utils/ConvertStringBn';
import { CurrentStatus } from '../components/FootballBanner';
import { FixPageTitle } from './PointTablePage';

function Result({ fixtureAr, timeStamp }) {
    return (
        <>
            {Object.keys(fixtureAr)?.map((row) => (
                <div className="football-fixture-body" key={row}>
                    <div className="football-fixture-body-title">{row}</div>
                    <div className="football-fixture-item">
                        {Object.values(fixtureAr[row])?.map((rowAr) => (
                            <div
                                className={`fixture-item ${
                                    Object.values(fixtureAr[row]).length === 3
                                        ? 'fixture-item-c'
                                        : null
                                }`}
                                style={{
                                    width: Object.values(fixtureAr[row]).length > 1 ? '' : '100%',
                                    borderRight:
                                        Object.values(fixtureAr[row]).length > 1 ? '' : 'unset'
                                }}
                                key={rowAr?.sl}
                            >
                                <div className="fixture-item-country">
                                    <h4 style={{ textAlign: 'right' }}>{rowAr?.team_1_bn}</h4>
                                    <img
                                        src={`https://bahannonews.com/${rowAr?.team_1_img}`}
                                        alt=""
                                    />
                                    {rowAr?.schedule + 6000 < timeStamp ? (
                                        <h5 style={{ whiteSpace: 'nowrap' }}>
                                            {ConvertStringBn(`${rowAr?.team_1_goal}`)} - {` `}
                                            {ConvertStringBn(`${rowAr?.team_2_goal}`)}
                                        </h5>
                                    ) : (
                                        <h5>
                                            <div />
                                        </h5>
                                    )}

                                    <img
                                        src={`https://bahannonews.com/${rowAr?.team_2_img}`}
                                        alt=""
                                    />
                                    <h4 style={{ textAlign: 'left' }}>{rowAr?.team_2_bn}</h4>
                                </div>
                                <div className="fixture-item-time">
                                    <CurrentStatus matchTime={rowAr?.schedule} />
                                </div>
                            </div>
                        ))}
                        {Object.values(fixtureAr[row]).length > 2 ? (
                            <div className="fixture-item-gap-design" />
                        ) : null}
                    </div>
                </div>
            ))}
        </>
    );
}

function Fixture({ fixtureAr, timeStamp }) {
    return (
        <>
            {Object.keys(fixtureAr)?.map((row) => (
                <div className="football-fixture-body" key={row}>
                    <div className="football-fixture-body-title">{row}</div>
                    <div className="football-fixture-item">
                        {Object.values(fixtureAr[row])?.map((rowAr) => (
                            <div
                                className={`fixture-item ${
                                    Object.values(fixtureAr[row]).length === 3
                                        ? 'fixture-item-c'
                                        : null
                                }`}
                                style={{
                                    width: Object.values(fixtureAr[row]).length > 1 ? '' : '100%',
                                    borderRight:
                                        Object.values(fixtureAr[row]).length > 1 ? '' : 'unset'
                                }}
                                key={rowAr?.sl}
                            >
                                <div className="fixture-item-country">
                                    <h4 style={{ textAlign: 'right' }}>{rowAr?.team_1_bn}</h4>
                                    <img
                                        src={`https://bahannonews.com/${rowAr?.team_1_img}`}
                                        alt=""
                                    />
                                    {rowAr?.schedule + 6000 < timeStamp ? (
                                        <h5 style={{ whiteSpace: 'nowrap' }}>
                                            {ConvertStringBn(`${rowAr?.team_1_goal}`)} -
                                            {ConvertStringBn(`${rowAr?.team_2_goal}`)}
                                        </h5>
                                    ) : (
                                        <h5>
                                            <div />
                                        </h5>
                                    )}

                                    <img
                                        src={`https://bahannonews.com/${rowAr?.team_2_img}`}
                                        alt=""
                                    />
                                    <h4 style={{ textAlign: 'left' }}>{rowAr?.team_2_bn}</h4>
                                </div>
                                <div className="fixture-item-time">
                                    <CurrentStatus matchTime={rowAr?.schedule} />
                                </div>
                            </div>
                        ))}
                        {Object.values(fixtureAr[row]).length > 2 ? (
                            <div className="fixture-item-gap-design" />
                        ) : null}
                    </div>
                </div>
            ))}
        </>
    );
}

function FixturesPage() {
    const [fixtureAr, setFixtureAr] = useState([]);
    const page = 'fixture';
    const timeStamp = new Date().getTime() / 1000;
    const [isTab, setIsTab] = useState('fixer');

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                [page]: 220
            }
        }).then(({ data }) => {
            setFixtureAr(data.articles);
        });
    }, []);

    useEffect(() => {
        // setIsTab('fixer');
        PageProperties('বিশ্বকাপ ফুটবল সূচি ২০২২');
    }, []);

    console.log(fixtureAr);
    if (!fixtureAr) {
        return (
            <div className="container">
                <div className="football-fixture">
                    <FixPageTitle isTab={isTab} setIsTab={setIsTab} />
                </div>
            </div>
        );
    }
    return (
        <div className="container">
            <div className="football-fixture">
                <FixPageTitle isTab={isTab} setIsTab={setIsTab} />
                {isTab === 'fixer' ? (
                    <Fixture fixtureAr={fixtureAr?.fixture || []} timeStamp={timeStamp} />
                ) : (
                    <Result fixtureAr={fixtureAr?.result || []} timeStamp={timeStamp} />
                )}
            </div>
        </div>
    );
}

export default FixturesPage;
