/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PageProperties from '../../../models/PageProperties';
import InitInfo from '../../../utils/InitInfo';
import { setActiveMenu } from '../../../utils/SetStoreParameters';

export default function ContactPage() {
    const dispatch = useDispatch();
    const { configs } = InitInfo();

    useEffect(() => {
        dispatch(setActiveMenu('/'));
    }, [dispatch]);

    PageProperties('যোগাযোগ');

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 mt-3 mb-3">
                    <div className="home-category-news-title" style={{ width: '100%' }}>
                        <div className="home-category-news-title-sub" style={{ width: 175 }}>
                            <Link to="privacy">যোগাযোগ</Link>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 col-xl-4">
                    <div className="details-news-body" style={{ width: '100%' }}>
                        <div className="details-news-body-detail">
                            <h3>Office</h3>
                            <p>{configs?.organizationAddress}</p>
                            <h3>Contact No</h3>
                            <p>Mobile: {configs?.organizationPhone}</p>
                            <h3>Email</h3>
                            <p>{configs?.organizationEmail}</p>
                            <h3>Website</h3>
                            <p>
                                <a href={configs?.frontUrl} target="_blank" rel="noreferrer">
                                    {configs?.frontUrl}
                                </a>
                            </p>
                            <h3>Follow us on</h3>
                            <p>
                                <a href={configs?.facebook} target="_blank" rel="noreferrer">
                                    {configs?.facebook}
                                </a>
                            </p>
                            <p />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-8 col-xl-8">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d912.977310103641!2d90.3869706!3d23.7506157!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9a96873d0ef%3A0xa62dfa3eb6eb64cb!2sBahanno%20News!5e0!3m2!1sen!2sbd!4v1681033679709!5m2!1sen!2sbd"
                        width="600"
                        height="450"
                        style={{ border: 0, width: '100%' }}
                        allowFullScreen=""
                        loading="lazy"
                    />
                </div>
            </div>
        </div>
    );
}
