/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon
} from 'react-share';
import { Tooltip } from 'react-tooltip';
import '../../assets/css/bottom-modal.css';
import CrossIcon from '../../assets/images/Cross.svg';
import copyIocn from '../../assets/images/copy-solid.svg';
import InitInfo from '../utils/InitInfo';

function ShareDetailBox({ articleLink, show, myRef, setShow, article }) {
    const [tooltip, setTooltip] = useState('Copy Link');
    const handleCopy = () => {
        navigator.clipboard.writeText(articleLink);
        setTooltip('লিঙ্ক কপি হয়েছে');
        Tooltip.rebuild();
    };
    const handleLinkClick = () => {
        setShow(false);
    };

    if (!show) {
        return null;
    }
    return (
        <div ref={myRef} className="details-share-box-modal">
            <table className="details-share-box-table">
                <tbody>
                    <tr className="recent-share-tr details-share-box-tr mb-4">
                        <td>
                            <FacebookShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={32} round />

                                <span className="share-text-modal fb-share-hover">
                                    ফেসবুকে শেয়ার করুন
                                </span>
                            </FacebookShareButton>
                        </td>
                        <td>
                            <FacebookMessengerShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookMessengerIcon size={32} round />

                                <span className="share-text-modal me-share-hover">
                                    {' '}
                                    ম্যাসেঞ্জারে শেয়ার করুন
                                </span>
                            </FacebookMessengerShareButton>
                        </td>
                    </tr>
                    <tr className="recent-share-tr details-share-box-tr">
                        <td>
                            <PinterestShareButton
                                url={articleLink}
                                media={article.image}
                                className="Demo__some-network__share-button"
                                onClick={handleLinkClick}
                            >
                                <PinterestIcon size={32} round />
                                <span className="share-text-modal pi-share-hover">
                                    পিন্টারিস্টে শেয়ার করুন
                                </span>
                            </PinterestShareButton>
                        </td>
                        <td>
                            <WhatsappShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <WhatsappIcon size={32} round />

                                <span className="share-text-modal wh-share-hover">
                                    ওয়াটসঅ্যাপে শেয়ার করুন
                                </span>
                            </WhatsappShareButton>
                        </td>
                    </tr>
                    <tr className="recent-share-tr details-share-box-tr">
                        <td>
                            {' '}
                            <TwitterShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <XIcon size={32} round />

                                <span className="share-text-modal tw-share-hover">
                                    <br />
                                    <small
                                        style={{
                                            position: 'absolute',
                                            fontSize: 13,
                                            top: 27,
                                            left: 36
                                        }}
                                    >
                                        (সাবেক টুইটার)
                                    </small>
                                </span>
                            </TwitterShareButton>
                        </td>
                        <td>
                            <LinkedinShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <LinkedinIcon size={32} round />

                                <span className="share-text-modal li-share-hover">
                                    লিংকডইনে শেয়ার করুন
                                </span>
                            </LinkedinShareButton>
                        </td>
                    </tr>
                    <tr className="recent-share-tr details-share-box-tr">
                        <td>
                            <EmailShareButton
                                url={articleLink}
                                className="Demo__some-network__share-button"
                            >
                                <EmailIcon size={32} round />

                                <span className="share-text-modal em-share-hover">ইমেইল করুন</span>
                            </EmailShareButton>
                        </td>
                        <td
                            onClick={handleCopy}
                            data-tip
                            data-for="copyTip"
                            onMouseMove={() => setTooltip('লিঙ্ক কপি করুন')}
                            style={{ cursor: 'pointer' }}
                        >
                            <Button
                                style={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%',
                                    padding: 0,
                                    marginRight: 0,
                                    display: 'unset'
                                }}
                            >
                                <img
                                    src={copyIocn}
                                    alt="Copy Icon"
                                    style={{ width: 20, height: 18 }}
                                />
                            </Button>
                            <span className="share-text-modal co-share-hover"> লিংক কপি করুন</span>
                            <Tooltip id="copyTip" place="top" effect="solid">
                                {tooltip}
                            </Tooltip>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default function RecentNewsModal({ show, handleClose, latestArticles }) {
    const ref = useRef();
    const { configs } = InitInfo();
    const [moreShareShow, setMoreShareShow] = useState(false);

    const handleMoreShare = () => {
        setMoreShareShow(true);
    };

    const handleClickOutside = (ev) => {
        if (!(ref && ref.current?.contains(ev.target))) {
            // alert('You clicked outside of me!');
            setMoreShareShow(false);
        }
    };
    const articleLink = `${configs.frontUrl}/details/article/${latestArticles?.sl}/`;

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    }, []);

    if (!latestArticles) {
        return null;
    }

    return (
        <>
            <Modal
                show={show}
                className="modal modal-bottom fade"
                data-backdrop={false}
                style={{ width: 0, zIndex: 9999999, left: 0 }}
                enforceFocus={false}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content bottom-modal-content">
                        <div className="modal-body">
                            <div className="recent-news-title">
                                <Button
                                    className="details-share-more"
                                    style={{ top: 0, right: 10 }}
                                    onClick={handleMoreShare}
                                />
                                <img
                                    onClick={() => handleClose(latestArticles.sl)}
                                    src={CrossIcon}
                                    alt="cross icon"
                                    style={{ float: 'right' }}
                                />
                            </div>
                            <div className="container">
                                <div className="recent-news-btn">
                                    <Link to="/news/all/">সদ্য পাওয়া</Link>
                                </div>
                                <Link
                                    to={latestArticles.link}
                                    className="recent-news"
                                    onClick={() => handleClose(latestArticles.sl)}
                                >
                                    {latestArticles.title}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <ShareDetailBox
                    articleLink={articleLink}
                    show={moreShareShow}
                    myRef={ref}
                    setShow={setMoreShareShow}
                    article={latestArticles}
                />
            </Modal>
        </>
    );
}
