import { Link } from 'react-router-dom';
import Iopt from '../../../utils/Iopt';

function BoxNewsRelated({ article }) {
    if (!article) {
        return null;
    }
    return (
        <Link to={article.link} className="category-hero-news-short link-area">
            <div className="related-news-box-img">
                <img src={Iopt(article.image, 360)} alt="news" />
            </div>
            <div className="hero-news-short-title hero-news-short-title2 link-title">
                {article.pre_title ? <span>{article.pre_title} / </span> : null}
                {article.title}
            </div>
        </Link>
    );
}

export default BoxNewsRelated;
