import React, { useEffect, useRef, useState } from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import InitInfo from '../utils/InitInfo';

function FbPagePreview() {
    const { configs } = InitInfo();
    const [fbPageWidth, setFbPageWidth] = useState(0);

    const ref = useRef();
    useEffect(() => {
        // ref
        // console.dir(ref.current.clientWidth);
        setFbPageWidth(ref?.current.clientWidth);
    }, []);

    return (
        <div style={{ marginBottom: '24px' }} ref={ref}>
            {fbPageWidth > 200 ? (
                <FacebookProvider appId={configs.fbAppId}>
                    <Page href={configs.facebook} width={fbPageWidth} messageus="" />
                </FacebookProvider>
            ) : null}
        </div>
    );
}

export default FbPagePreview;
