export function setActiveMenu(payload) {
    return {
        type: 'ACTIVE_MENU_SET',
        payload
    };
}

export function setLoadingStatus(payload) {
    return {
        type: 'SET_LOADING_STATUS',
        payload
    };
}

function SetStoreParameters() {
    return null;
}

export default SetStoreParameters;
