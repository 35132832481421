import React from 'react';
import { Link } from 'react-router-dom';
import PlayButton from '../../../components/PlayButton';
import Iopt from '../../../utils/Iopt';

export default function SpecialBoxNews({ article }) {
    if (!article) {
        return null;
    }
    return (
        <Link to={article.link} className="special-news-box link-area">
            <div className="hero-news-short-img image-area">
                <img src={Iopt(article.image, 320)} alt={article.title} />
                <PlayButton show={article.having_video} />
            </div>
            <div className="special-news-title link-title">
                {article.pre_title ? <span>{article.pre_title} / </span> : null}
                {article.title}
            </div>
            <div className="special-news-detail">{article.summery}</div>
        </Link>
    );
}
