/* eslint-disable jsx-a11y/iframe-has-title */
import { useLocation } from 'react-router-dom';
import { Ads05, Ads06, Ads08, Ads09, Ads11, Ads14Full, Ads17, Ads19 } from './Ads';
// <a href={adLink} target="_blank" rel="noreferrer">
// 1306x129
export function XlWindy() {
    return (
        <iframe
            width="341"
            height="230"
            src="https://embed.windy.com/embed2.html?lat=15.284&lon=94.307&detailLat=15.771&detailLon=92.637&width=341&height=250&zoom=3&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
            frameBorder="0"
        />
    );
}
export function XlHome01() {
    const adId = 'Xl-home-01';
    const adSize = '1306x129';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');
    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;

        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                <div className="placeholder-ads" style={{ width: '1306px', height: '129px' }}>
                    1306x129
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlHome02() {
    const adId = 'Xl-home-02';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }

    return <Ads05 show />;
}
// 296x250
export function XlHome03() {
    const adId = 'Xl-home-03';
    const adSize = '296x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '296px', height: '250px' }}>
                    296x250
                </div>
            </a>
        );
    }

    return <Ads08 show />;
}
// 296x250
export function XlHome04() {
    const adId = 'Xl-home-04';
    const adSize = '296x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '296px', height: '250px' }}>
                    296x250
                </div>
            </a>
        );
    }

    return <Ads09 show />;
}
// 296x250
export function XlHome05() {
    const adId = 'Xl-home-05';
    const adSize = '296x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '296px', height: '250px' }}>
                    296x250
                </div>
            </a>
        );
    }
    return <Ads06 show name="gpt-passback6" />;
}
// 940x100
export function XlHome06() {
    const adId = 'Xl-home-05';
    const adSize = '940x100';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '940px', height: '100px' }}>
                    940x100
                </div>
            </a>
        );
    }
    return <Ads14Full show mode="placeholder" />;
}
// 341x436
export function XlHome07() {
    const adId = 'Xl-home-07';
    const adSize = '341x436';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '341px', height: '436px' }}>
                    341x436
                </div>
            </a>
        );
    }
    return <Ads19 show />;
}
// 758x90
export function XlHome08() {
    const adId = 'Xl-home-08';
    const adSize = '758x90';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '758px', height: '90px' }}>
                    758x90
                </div>
            </a>
        );
    }
    return <Ads11 show mode="placeholder" />;
}
// 758x90
export function XlHome09() {
    const adId = 'Xl-home-09';
    const adSize = '758x90';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '758px', height: '90px' }}>
                    758x90
                </div>
            </a>
        );
    }
    return <Ads11 show mode="placeholder" />;
}
// 758x90
export function XlHome10() {
    const adId = 'Xl-home-10';
    const adSize = '758x90';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '758px', height: '90px' }}>
                    758x90
                </div>
            </a>
        );
    }
    return <Ads14Full show mode="placeholder" />;
}
// 758x90
export function XlHome11() {
    const adId = 'Xl-home-11';
    const adSize = '758x90';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '758px', height: '90px' }}>
                    758x90
                </div>
            </a>
        );
    }
    return <Ads14Full show mode="placeholder" />;
}

// 1306x129
export function XlCate01() {
    const adId = 'Xl-cate-01';
    const adSize = '1306x129';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '1306px', height: '129px' }}>
                    1306x129
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlCate02() {
    const adId = 'Xl-cate-02';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    350x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlCate03() {
    const adId = 'Xl-cate-03';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 296x250
export function XlCate04() {
    const adId = 'Xl-home-04';
    const adSize = '296x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '296px', height: '250px' }}>
                    296x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 296x250
export function XlCate05() {
    const adId = 'Xl-home-05';
    const adSize = '296x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '296px', height: '250px' }}>
                    296x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 296x250
export function XlCate06() {
    const adId = 'Xl-home-06';
    const adSize = '296x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '296px', height: '250px' }}>
                    296x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlCate07() {
    const adId = 'Xl-cate-07';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 940x100
export function XlCate08() {
    const adId = 'Xl-cate-08';
    const adSize = '940x100';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '940px', height: '100px' }}>
                    940x100
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 341x184
export function XlCate09() {
    const adId = 'Xl-cate-08';
    const adSize = '341x184';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '341px', height: '184px' }}>
                    341x184
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}

// 1306x129
export function XlDetail01() {
    const adId = 'Xl-det-01';
    const adSize = '1306x129';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '1306px', height: '129px' }}>
                    1306x129
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlDetail02() {
    const adId = 'Xl-det-02';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlDetail03() {
    const adId = 'Xl-det-03';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 296x250
export function XlDetail04() {
    const adId = 'Xl-det-03';
    const adSize = '296x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '296px', height: '250px' }}>
                    296x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlDetail05() {
    const adId = 'Xl-det-05';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 341x184
export function XlDetail06() {
    const adId = 'Xl-det-06';
    const adSize = '341x184';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '341px', height: '184px' }}>
                    341x184
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}

// 1306x129
export function XlEduDetail01() {
    const adId = 'Xl-eduDet-01';
    const adSize = '1306x129';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '1306px', height: '129px' }}>
                    1306x129
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlEduDetail02() {
    const adId = 'Xl-eduDet-02';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlEduDetail03() {
    const adId = 'Xl-eduDet-03';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 296x250
export function XlEduDetail04() {
    const adId = 'Xl-eduDet-04';
    const adSize = '296x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '296px', height: '250px' }}>
                    296x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 296x250
export function XlEduDetail05() {
    const adId = 'Xl-eduDet-05';
    const adSize = '296x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '296px', height: '250px' }}>
                    296x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlEduDetail06() {
    const adId = 'Xl-eduDet-06';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 341x184
export function XlEduDetail07() {
    const adId = 'Xl-eduDet-07';
    const adSize = '341x184';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '341px', height: '184px' }}>
                    341x184
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}

// 1306x129
export function XlAll01() {
    const adId = 'Xl-all-01';
    const adSize = '1306x129';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '1306px', height: '129px' }}>
                    1306x129
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlAll02() {
    const adId = 'Xl-all-02';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlAll03() {
    const adId = 'Xl-all-03';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlAll04() {
    const adId = 'Xl-all-04';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 341x184
export function XlAll05() {
    const adId = 'Xl-all-05';
    const adSize = '341x184';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '341px', height: '184px' }}>
                    341x184
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}

// 1306x129
export function XlArch01() {
    const adId = 'Xl-arch-01';
    const adSize = '1306x129';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '1306px', height: '129px' }}>
                    1306x129
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlArch02() {
    const adId = 'Xl-arch-02';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlArch03() {
    const adId = 'Xl-arch-03';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 342x250
export function XlArch04() {
    const adId = 'Xl-arch-04';
    const adSize = '342x250';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '342px', height: '250px' }}>
                    342x250
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 341x184
export function XlArch05() {
    const adId = 'Xl-arch-05';
    const adSize = '341x184';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '341px', height: '184px' }}>
                    341x184
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}
// 940x100
export function XlArch06() {
    const adId = 'Xl-arch-06';
    const adSize = '940x100';

    const { search } = useLocation();

    const adSetup = new URLSearchParams(search).get('ads');

    if (adSetup) {
        const adLink = `${process.env.REACT_APP_API_URL}/manage/ads/manage/?id=${adId}&size=${adSize}`;
        return (
            <a href={adLink} target="_blank" rel="noreferrer">
                {' '}
                <div className="placeholder-ads" style={{ width: '940px', height: '100px' }}>
                    940x100
                </div>
            </a>
        );
    }
    return <Ads17 show />;
}

function AdManagerXl() {
    return <div>AdManager</div>;
}

export default AdManagerXl;
