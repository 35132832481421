import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useLastUpdate } from '../../../contexts/LastUpdateProvider';
import PageProperties from '../../../models/PageProperties';
import AxiosAuth from '../../../utils/AxiosAuth';
import { setActiveMenu } from '../../../utils/SetStoreParameters';
import DefaultSidebarSection from '../section/DefaultSidebarSection';
import TagSection from '../section/TagSection';

function TagPageControl() {
    const dispatch = useDispatch();
    const location = useLocation();
    const pageId = '/';

    const q = new URLSearchParams(location.search).get('q');
    const page = `tag-${q}`;
    const [articleData, setArticleData] = useState(null);
    const { article } = useLastUpdate();

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                [page]: 300
            }
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [page, article]);

    useEffect(() => {
        dispatch(setActiveMenu(pageId));
        PageProperties(`${q.replace('#', '')}`);
    }, [dispatch, q]);

    if (articleData === null) {
        return null;
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <TagSection articles={articleData[page] || []} q={q} />
                <DefaultSidebarSection
                    latestArticles={articleData.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function TagPage() {
    const { q } = useParams();

    return <TagPageControl key={q} />;
}
