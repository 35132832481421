import { onValue, ref } from 'firebase/database';
import { createContext, useContext, useEffect, useState } from 'react';
import RecentNewsModal from '../components/RecentNewsModal';
import { rtDb } from '../MyFirebase';

const LastUpdateContext = createContext();

export function useLastUpdate() {
    return useContext(LastUpdateContext);
}

function LastUpdateProvider({ children }) {
    const [updated, setUpdated] = useState('');
    const [article, setArticle] = useState(null);
    const sl = article?.sl;
    const published = article?.published || 0;

    const closeRecentNews = () => {
        setArticle(null);
    };

    useEffect(() => {
        const dbRef = ref(rtDb, `/last-update/article/`);
        onValue(dbRef, (snapshot) => {
            const art = snapshot.val();
            setArticle(art);
        });
    }, []);

    const value = {
        updated,
        setUpdated,
        article,
        setArticle
    };

    const storeSl = localStorage.getItem('latest-article');
    if (article && storeSl !== sl?.toString()) {
        article.status = 'updated';
    }

    if (sl) {
        localStorage.setItem('latest-article', sl);
    }

    const timeNow = Math.round(Date.now() / 1000);

    return (
        <LastUpdateContext.Provider value={value}>
            {children}

            <RecentNewsModal
                show={article?.status === 'updated' && timeNow - published < 600}
                handleClose={closeRecentNews}
                latestArticles={article}
            />
        </LastUpdateContext.Provider>
    );
}

export default LastUpdateProvider;
