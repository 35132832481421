/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/images/Search.svg';
import ArrowDown from '../../../../assets/images/chevron-down-solid.svg';
import ArrowUp from '../../../../assets/images/chevron-up-solid.svg';
import TimestampToDate from '../../../models/TimestampToDate';
import ConvertStringBn from '../../../utils/ConvertStringBn';
import Iopt from '../../../utils/Iopt';

const SearchArea = ({ q }) => {
    const navigate = useNavigate();

    const [searchKey, setSearchKey] = useState(q);

    const onSearchClick = (ev, mode) => {
        const moveToSearch = () => {
            navigate(`/search/?q=${encodeURIComponent(searchKey || '')}`);
        };

        if (mode === 'change') {
            setSearchKey(ev.target.value);
        }

        if (mode === 'keyup') {
            if (ev.keyCode === 13) {
                // On press enter
                moveToSearch();
            } else {
                setSearchKey(ev.target.value);
            }
        } else if (mode === 'searchClick') {
            moveToSearch();
        }
    };
    useEffect(() => {
        if (q) {
            setSearchKey(q);
        }
    }, [q]);
    return (
        <div className="header-search-group">
            <div className="header-search-text-input">
                <input
                    placeholder="অনুসন্ধান করুন..."
                    onKeyUp={(ev) => {
                        onSearchClick(ev, 'keyup');
                    }}
                    value={searchKey || ''}
                    onChange={(ev) => {
                        onSearchClick(ev, 'change');
                    }}
                />
            </div>
            <button
                type="button"
                className="header-search-send"
                onClick={(ev) => {
                    onSearchClick(ev, 'searchClick');
                }}
            >
                <img src={SearchIcon} alt="Send Icon" />
            </button>
        </div>
    );
};
function AuthorList({ authorData, setFilterAuthor, filterAuthor }) {
    const handleChange = (ev) => {
        const index = filterAuthor.findIndex((prod) => prod === ev);
        if (index > -1) {
            setFilterAuthor((current) => current.filter((element) => element !== ev));
        } else {
            setFilterAuthor((filters) => [...filters, ev]);
        }
    };

    return (
        <>
            {authorData?.map((author) => (
                <div className="form-check" key={author}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={author}
                        onChange={() => handleChange(author)}
                        checked={filterAuthor.findIndex((prod) => prod === author) > -1}
                    />
                    <label className="form-check-label" htmlFor={author}>
                        {author}
                    </label>
                </div>
            ))}
        </>
    );
}
function SectionList({ sectionData, setFilterSection, filterSection }) {
    const handleChange = (ev) => {
        const index = filterSection.findIndex((prod) => prod === ev);
        if (index > -1) {
            setFilterSection((current) => current.filter((element) => element !== ev));
        } else {
            setFilterSection((filters) => [...filters, ev]);
        }
    };

    return (
        <>
            {sectionData?.map((section) => (
                <div className="form-check" key={section.sl}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id={section.sl}
                        onChange={() => handleChange(section.page_id)}
                        checked={filterSection.findIndex((prod) => prod === section.page_id) > -1}
                    />
                    <label className="form-check-label" htmlFor={section.sl}>
                        {section?.title}
                    </label>
                </div>
            ))}
        </>
    );
}
function TypeList() {
    return (
        <>
            <div className="form-check" key="type1">
                <input
                    className="form-check-input"
                    name="flexRadioDefault"
                    type="radio"
                    id="type1"
                />
                <label className="form-check-label" htmlFor="type1">
                    সব
                </label>
            </div>
            <div className="form-check" key="type2">
                <input
                    className="form-check-input"
                    name="flexRadioDefault"
                    type="radio"
                    id="type2"
                />
                <label className="form-check-label" htmlFor="type2">
                    টেক্সট
                </label>
            </div>
            <div className="form-check" key="type3">
                <input
                    className="form-check-input"
                    name="flexRadioDefault"
                    type="radio"
                    id="type3"
                />
                <label className="form-check-label" htmlFor="type3">
                    ছবি
                </label>
            </div>
        </>
    );
}
function OrientationList({ filterOrintation, setFilterOrintation }) {
    const handleOrintation = (ev) => {
        setFilterOrintation(ev);
    };
    return (
        <>
            <div className="form-check" key="ore1">
                <input
                    className="form-check-input"
                    name="orientationList"
                    type="radio"
                    id="ore2"
                    onClick={() => handleOrintation('relevant')}
                    value={filterOrintation}
                    checked={filterOrintation === 'relevant'}
                />
                <label className="form-check-label" htmlFor="ore2">
                    প্রাসঙ্গিক
                </label>
            </div>
            <div className="form-check" key="ore2">
                <input
                    className="form-check-input"
                    name="orientationList"
                    type="radio"
                    id="ore2"
                    onClick={() => handleOrintation('new')}
                    value={filterOrintation}
                    checked={filterOrintation === 'new'}
                />
                <label className="form-check-label" htmlFor="ore2">
                    নতুন থেকে পুরোনো
                </label>
            </div>
            <div className="form-check" key="ore3">
                <input
                    className="form-check-input"
                    name="orientationList"
                    type="radio"
                    id="ore3"
                    onClick={() => handleOrintation('old')}
                    value={filterOrintation}
                    checked={filterOrintation === 'old'}
                />
                <label className="form-check-label" htmlFor="ore3">
                    পুরোনো থেকে নতুন
                </label>
            </div>
        </>
    );
}
function SearchBox({ article }) {
    return (
        <Link to={article.link} className="search-section-box link-area">
            <div className="search-section-box-info link-title align-self-center">
                <h4 className="link-title">
                    {article.pre_title ? <span>{article.pre_title} / </span> : null}
                    {article.title}
                </h4>
                <h5>{article.summery}</h5>
                <p className="category-news-f-time float-right">
                    <TimestampToDate time={article.time_published} />
                </p>
            </div>
            <div className="search-section-box-img">
                <img src={Iopt(article.image, 360)} alt="news" />
            </div>
        </Link>
    );
}

export default function SearchSection2({ articles, q, authorData, sectionData, handleDateRange }) {
    const [filterAuthor, setFilterAuthor] = useState([]);
    const [filterSection, setFilterSection] = useState([]);
    const [filterOrintation, setFilterOrintation] = useState('relevant');
    const [showFilter, setShowFilter] = useState('');
    const [arrowKey, setArrowKey] = useState();
    const [moreCount, setMoreCount] = useState(50);
    const [authorSearchText, setAuthorSearchText] = useState('');

    let filterArticle = articles;
    let filterOrginalArticle = articles;

    const authorDataSearchAr = authorData.filter((item) =>
        item?.toLowerCase().includes(authorSearchText?.toLowerCase())
    );
    if (filterAuthor.length > 0) {
        filterArticle = articles.filter((i) =>
            i.author.split(',').some((filA) => filterAuthor.includes(filA))
        );
        filterOrginalArticle = articles.filter((i) =>
            i.author.split(',').some((filA) => filterAuthor.includes(filA))
        );
    }
    if (filterSection.length > 0) {
        filterArticle = articles.filter((j) =>
            j.page.split(',').some((filP) => filterSection.includes(filP))
        );
        filterOrginalArticle = articles.filter((j) =>
            j.page.split(',').some((filP) => filterSection.includes(filP))
        );
    }

    if (filterOrintation === 'new') {
        filterArticle = filterArticle?.sort((a, b) =>
            a.time_published > b.time_published ? -1 : 1
        );
    }

    if (filterOrintation === 'old') {
        filterArticle = filterArticle?.sort((a, b) =>
            a.time_published > b.time_published ? 1 : -1
        );
    }
    if (filterOrintation === 'relevant') {
        filterArticle = filterOrginalArticle;
    }

    const handleArrow = (ev) => {
        if (arrowKey === ev) {
            setArrowKey();
        } else {
            setArrowKey(ev);
        }
    };
    const handleRemoveFilter = () => {
        setFilterAuthor([]);
        setFilterSection([]);
    };

    const handleMoreCount = () => {
        setMoreCount(moreCount + 50);
    };
    const totalArticle = articles.length;

    const ArrowUpImg = <img src={ArrowUp} style={{ width: 20, float: 'right' }} alt="" />;
    const ArrowDownImg = <img src={ArrowDown} style={{ width: 20, float: 'right' }} alt="" />;

    return (
        <div className="col col-840">
            <div className="row">
                <div className="col-12 col-lg-4 col-xl-4 col-md-5">
                    <h4>অনুসন্ধান</h4>
                </div>
                <div className="col-12 col-lg-8 col-xl-8 col-md-7">
                    <div className="search-page-input">
                        <SearchArea q={q} />
                    </div>
                </div>
                <div className="col-12 search-section-devider">
                    <hr />
                </div>
                <div className="col-12 col-lg-4 col-xl-4 col-md-5">
                    <div className="search-page-sidebar">
                        <div className="search-page-sidebar-title">
                            প্রাপ্ত ফলাফল: {ConvertStringBn(`${filterArticle.length}`)}
                            {filterSection.length > 0 || filterAuthor.length > 0 ? (
                                <small
                                    style={{ float: 'right', color: 'red', cursor: 'pointer' }}
                                    onClick={handleRemoveFilter}
                                >
                                    মুছে ফেলুন{' '}
                                    {ConvertStringBn(
                                        `${filterSection.length + filterAuthor.length}`
                                    )}
                                </small>
                            ) : (
                                <>
                                    <span
                                        role="button"
                                        className={`${showFilter} search-cancel-btn `}
                                        onClick={() => setShowFilter('')}
                                    >
                                        Cancel
                                    </span>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setShowFilter('search-page-sidebar-accordion')
                                        }
                                    >
                                        বাছাই করুন
                                    </button>
                                </>
                            )}
                        </div>

                        <Accordion className={showFilter}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>লেখক</Accordion.Header>
                                <Accordion.Body>
                                    <input
                                        type="text"
                                        placeholder="লেখক সার্চ"
                                        onChange={(e) => setAuthorSearchText(e.target.value)}
                                        value={authorSearchText}
                                        className="search-author-input"
                                    />
                                    <AuthorList
                                        authorData={authorDataSearchAr}
                                        setFilterAuthor={setFilterAuthor}
                                        filterAuthor={filterAuthor}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>সেকশন</Accordion.Header>
                                <Accordion.Body>
                                    <SectionList
                                        sectionData={sectionData}
                                        setFilterSection={setFilterSection}
                                        filterSection={filterSection}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>ধরন</Accordion.Header>
                                <Accordion.Body>
                                    <TypeList />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header>সাজানো</Accordion.Header>
                                <Accordion.Body>
                                    <OrientationList
                                        setFilterOrintation={setFilterOrintation}
                                        filterOrintation={filterOrintation}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>তারিখ</Accordion.Header>
                                <Accordion.Body>
                                    <div className="search-section-date-input">
                                        <input
                                            type="date"
                                            className="form-control"
                                            onChange={handleDateRange}
                                            name="startDate"
                                        />
                                        <span>To</span>
                                        <input
                                            type="date"
                                            className="form-control"
                                            onChange={handleDateRange}
                                            name="endDate"
                                        />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                {filterArticle?.length < 1 ? (
                    <div
                        className="col-12 col-lg-8 col-xl-8 col-md-7"
                        style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            paddingTop: '100px',
                            fontSize: 20
                        }}
                    >
                        Searching...
                    </div>
                ) : (
                    <div className="col-12 col-lg-8 col-xl-8 col-md-7">
                        {Object.values(filterArticle)
                            .slice(0, `${moreCount}`)
                            .map((art) => (
                                <div className="col-12" key={art.sl}>
                                    <SearchBox article={art} />
                                </div>
                            ))}
                        <div className="category-news-more-btn" onClick={handleMoreCount}>
                            <Button>{totalArticle <= moreCount ? 'শেষ ' : 'আরও'}</Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
