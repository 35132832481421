/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { Ads05, Ads06, Ads08, Ads09, Ads14Full } from '../../../components/Ads';
import HeroNews from '../../../components/HeroNews';
import PickCategoryByPageId from '../../../models/PickCategoryByPageId';
import TimestampToDate from '../../../models/TimestampToDate';
import AxiosAuth from '../../../utils/AxiosAuth';
import BrowserInfo from '../../../utils/BrowserInfo';
import Iopt from '../../../utils/Iopt';
import BoxNewsCategory from '../components/BoxNewsCategory';
import BoxNews from '../components/BoxNewsDetail';
import CategorySection01Lg from './CategorySection01Lg';
import CategorySection01Md from './CategorySection01Md';

function CategorySection01Main({ category, pageId, articlesAr }) {
    const cat = PickCategoryByPageId(category, pageId);
    const [moreCount, setMoreCount] = useState(15);
    const [articlesArrr, setArticlesArrr] = useState(articlesAr);
    const [lastId, setLastId] = useState(null);
    const handleMoreCount = () => {
        setMoreCount(moreCount + 10);
    };

    const handleMoreCount2 = () => {
        AxiosAuth.post(ConfigApi.MORE_CATEGORE_DATA, {
            category,
            pageId,
            lastId
        }).then(({ data }) => {
            setLastId(data.lastId);
            setArticlesArrr([...articlesArrr, ...data.dataAr]);
        });
    };
    const articles = articlesArrr?.sort((item1, item2) => {
        if (item1.time_published < item2.time_published) {
            return 1;
        }
        if (item1.time_published > item2.time_published) {
            return -1;
        }

        return 0;
    });
    const totalCat = articles?.length;
    // bangladesh
    // international
    // expatriation
    // technology
    // lifestyle
    // health

    let tBg = 'home-category-news-title';
    if (
        pageId === 'bangladesh' ||
        pageId === 'international' ||
        pageId === 'expatriation' ||
        pageId === 'technology' ||
        pageId === 'lifestyle' ||
        pageId === 'health' ||
        pageId === 'education'
    ) {
        tBg = 'home-category-news-title-green';
    }
    return (
        <div className="col col-840">
            <div className="row">
                <div className="col-12">
                    <div className={tBg}>
                        <div className="home-category-news-title-sub">
                            <Link to={cat.url}>{cat.title}</Link>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-510">
                    <HeroNews article={articles[0]} />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col d-lg-none d-block mb-3">
                    <Ads05 show />
                </div>
                <div className="col-12 col-lg-4 col-xl-4 col-300 ">
                    <BoxNews article={articles[1]} />
                    <BoxNews article={articles[2]} />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col d-none  d-lg-block d-xl-block mb-4">
                    <Ads08 show />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col d-none  d-lg-block">
                    <Ads09 show mode="placeholder" />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col-4 d-none d-lg-block">
                    <Ads09 show name="gpt-passback6" />
                </div>

                <div className=" col-12 col-lg-4 col-xl-4 col d-lg-none d-xl-none d-md-none mb-4">
                    <Ads09 show />
                </div>
                <div className="col-12 col-lg-4 col-xl-4  ">
                    <BoxNewsCategory article={articles[3]} />
                </div>
                <div className="col-12 col-lg-4 col-xl-4   ">
                    <BoxNewsCategory article={articles[4]} />
                </div>

                <div className=" col-12 col-lg-4 col-xl-4 col d-lg-none d-xl-none d-md-none mb-4">
                    <Ads08 show />
                </div>
                <div className="col-12 col-lg-4 col-xl-4   ">
                    <BoxNewsCategory article={articles[5]} />
                </div>
                <div className="col-12 col-lg-4 col-xl-4    ">
                    <BoxNewsCategory article={articles[6]} />
                </div>
                <div className=" col-12 col-lg-4 col-xl-4 col d-lg-none d-xl-none d-md-none mb-4">
                    <Ads06 show />
                </div>
                <div className="col-12 col-lg-4 col-xl-4   ">
                    <BoxNewsCategory article={articles[7]} />
                </div>
                <div className="col-12 col-lg-4 col-xl-4   ">
                    <BoxNewsCategory article={articles[8]} />
                </div>
                {/* <Ads11 show /> */}
                <Ads14Full show mode="placeholder" />

                <div className="col-12">
                    {Object.values(articles)
                        .slice(9, moreCount)
                        ?.map((rows, index) => (
                            <div className="col-12" key={rows.sl}>
                                <Link to={rows.link} className="category-news link-area">
                                    <div className="category-news-img align-self-center">
                                        <img src={Iopt(rows.image, 360)} alt="news" />
                                    </div>
                                    <div className="category-news-title link-title align-self-center">
                                        <h4 className="link-title">
                                            {rows.pre_title ? (
                                                <span>{rows.pre_title} / </span>
                                            ) : null}
                                            {rows.title}
                                        </h4>
                                        <h5>{rows.summery}</h5>
                                        <p className="category-news-f-time float-right">
                                            <TimestampToDate time={rows.time_published} />
                                        </p>
                                    </div>
                                </Link>

                                {index % 4 === 0 ? (
                                    index !== 0 ? (
                                        <Ads14Full show mode="placeholder" />
                                    ) : null
                                ) : null}
                            </div>
                        ))}
                </div>

                <div className="col-12">
                    <div
                        className="category-news-more-btn"
                        onClick={totalCat <= moreCount ? handleMoreCount2 : handleMoreCount}
                    >
                        <Button>{totalCat <= moreCount ? 'আরও ' : 'আরও'}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CategorySection01({ category, pageId, articles, latestArticles }) {
    const browserData = BrowserInfo();
    if (browserData.sizeName === 'bs-lg') {
        return <CategorySection01Lg category={category} pageId={pageId} articlesAr={articles} />;
    }
    if (browserData.sizeName === 'bs-md') {
        return (
            <CategorySection01Md
                category={category}
                pageId={pageId}
                articlesAr={articles}
                latestArticles={latestArticles}
            />
        );
    }

    return <CategorySection01Main category={category} pageId={pageId} articlesAr={articles} />;
}

export default CategorySection01;
