import { onValue, ref } from 'firebase/database';
import { createContext, useContext, useEffect, useState } from 'react';
import ConfigApi from '../../configs/ConfigApi';
import { rtDb } from '../MyFirebase';
import AxiosAuth from '../utils/AxiosAuth';
import { useAuth } from './AuthProvider';
import { useTemplate } from './TemplateProvider';

const CommentContext = createContext();

export function useComment() {
    return useContext(CommentContext);
}

function CommentProvider({ children, id, currentUser }) {
    const { setMessage } = useTemplate();
    const [commentText, setCommentText] = useState('');
    const [comments, setComments] = useState([]);
    const [inName, setInName] = useState('main');
    const { openLoginModal } = useAuth();

    const addComments = (parentSl) => {
        if (!commentText) {
            setMessage('Please enter your comment');
            return null;
        }

        AxiosAuth.currentUserAuth(currentUser)
            .post(`${ConfigApi.API_COMMENT}add/`, {
                articleSl: id,
                parentSl,
                commentText
            })
            .then((data) => {
                setMessage(data.data.message);
            });

        // setComments((cuData) => {
        //     cuData.push({
        //         commentId: '00000',
        //         profileId: '00000',
        //         profileName: 'Okkk',
        //         profileImage: '',
        //         profileEmail: null,
        //         comment: commentText,
        //         time: '',
        //         likeCount: 0,
        //         dislikeCount: 0,
        //         likeCode: 0,
        //         childes: [],
        //     });

        //     return cuData;
        // });

        setCommentText('');
        return null;
    };

    const removeComments = (commentId) => {
        AxiosAuth.currentUserAuth(currentUser)
            .post(`${ConfigApi.API_COMMENT}remove/`, {
                id,
                commentId
            })
            .then((data) => {
                setMessage(data.data.message);
            });
        return null;
    };

    const addLike = (data) => {
        if (currentUser?.email === null) {
            openLoginModal();
            return null;
        }
        if (!data) {
            setMessage('Please Click Button');
            return null;
        }

        AxiosAuth.currentUserAuth(currentUser)
            .post(`${ConfigApi.API_LIKE}add/`, {
                articleSl: id,
                data
            })
            .then((data2) => {
                setMessage(data2.data.message);
            });
        return null;
    };

    useEffect(() => {
        const dbRef = ref(rtDb, `/comments/${id}`);
        onValue(dbRef, (snapshot) => {
            setComments(snapshot.val());
        });
    }, [currentUser, id]);

    const value = {
        id,
        currentUser,
        comments,
        addComments,
        removeComments,
        commentText,
        setCommentText,
        addLike,
        inName,
        setInName
    };

    return <CommentContext.Provider value={value}>{children}</CommentContext.Provider>;
}

export default CommentProvider;
