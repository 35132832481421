import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { XlHome01 } from '../../../components/AdManagerXl';
import TopMenuModal from '../../../components/TopMenuModal';
import InitInfo from '../../../utils/InitInfo';
import T20Banner from '../components/T20Banner';

function classNameControl(defClassName, top) {
    const newClassList = [defClassName];

    if (top > 140) {
        newClassList.push();

        newClassList.push('position-higher');
    } else {
        newClassList.push('position-lower');
    }

    return newClassList;
}

function MenuSection() {
    const headerRef = useRef();
    const headerRef2 = useRef();
    const { categoryAr } = InitInfo();
    const { pathname } = useLocation();

    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const activeMenu = useSelector((state) => state.activeMenu);

    const showHandle = () => {
        setShow(true);
    };

    const hideHandle = () => {
        setShow(false);
    };

    // const showHandleModal = () => {
    //     setShowModal(true);
    // };

    const hideHandleModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        setShowModal(false);
        if (headerRef?.current) {
            headerRef.current.className = classNameControl(
                'header-menu d-none d-md-block d-lg-block d-xl-block',
                0
            ).join(' ');

            headerRef2.current.className = classNameControl('fake-height', 0).join(' ');

            const onScroll = (e) => {
                const topPosition = e?.target?.documentElement?.scrollTop || '';
                headerRef.current.className = classNameControl(
                    'header-menu d-none d-md-block d-lg-block d-xl-block',
                    topPosition
                ).join(' ');

                headerRef2.current.className = classNameControl('fake-height', topPosition).join(
                    ' '
                );
            };
            window.addEventListener('scroll', onScroll);

            return () => window.removeEventListener('scroll', onScroll);
        }
        return null;
    }, [pathname]);

    return (
        <>
            <div ref={headerRef2} />
            <div
                className="header-menu d-none d-lg-block d-xl-block not-print-class"
                ref={headerRef}
            >
                <div className="container not-print-class">
                    <div className="menu-view">
                        <ul>
                            <li
                                className={
                                    activeMenu === '/' && pathname === '/' ? 'menu-view-active' : ''
                                }
                            >
                                <a href="/">প্রচ্ছদ</a>
                            </li>
                            <li
                                className={pathname === '/news/all/' ? 'menu-view-active' : ''}
                                key={101}
                            >
                                <Link to="/news/all/" className="link-title">
                                    সর্বশেষ
                                </Link>
                            </li>
                            {categoryAr.online &&
                                categoryAr.online.slice(0, 13).map((item) => (
                                    <li
                                        className={
                                            activeMenu === item.page_id ? 'menu-view-active' : ''
                                        }
                                        key={item.page_id}
                                    >
                                        <Link to={item.url || '/'}>{item.title}</Link>
                                    </li>
                                ))}
                            {/* <li className="more-menu">
                                <button
                                    type="button"
                                    style={{
                                        paddingRight: 0,
                                        border: 0,
                                        background: 'none'
                                    }}
                                    onClick={showHandleModal}
                                >
                                    <img
                                        src={ListIcon}
                                        alt="List Icon"
                                        color="white"
                                        style={{
                                            width: 16,
                                            position: 'relative',
                                            left: '-10px',
                                            color: 'white',
                                            top: '-1px'
                                        }}
                                    />
                                    আরও...
                                </button>
                            </li> */}
                        </ul>
                    </div>

                    <div
                        className={show ? 'more-menu-show' : 'more-menu-hide'}
                        onMouseEnter={showHandle}
                        onMouseLeave={hideHandle}
                    >
                        <ul>
                            {categoryAr.online &&
                                categoryAr.online.slice(12, 18).map((item) => (
                                    <li
                                        className={
                                            activeMenu === item.category_page
                                                ? 'menu-view-active'
                                                : ''
                                        }
                                        key={item.page_id}
                                    >
                                        <Link to={item.url || '/'}>{item.title}</Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mb-4 not-print-class" />
            <TopMenuModal show={showModal} handleClose={hideHandleModal} />

            <div className="container not-print-class">
                {/* Show on Desktop */}
                <div className="d-none d-md-block">
                    <T20Banner />
                    {/* <Ads10 show /> */}
                    <XlHome01 />
                </div>
                <div className="d-block d-md-none d-lg-none d-xl-none" style={{ marginBottom: 10 }}>
                    {/* <Ads17 show /> */}
                    <XlHome01 />
                </div>

                {/* Show on Mobile */}
                {/* <div className="d-block d-md-none">
                    <Ads05 show />
                </div> */}
            </div>
        </>
    );
}

export default MenuSection;
