const api = process.env.REACT_APP_API_URL;
const apiNotifi = process.env.REACT_APP_URL_NOTIFI;

const ConfigApi = {
    API_LOGIN: `${api}/bikiran/auth/`,
    API_APP_INIT: `${api}/api-2/init/`,
    API_SELECTED_ARTICLES: `${api}/api-2/selected-articles/`,
    API_CAT_PAGE_ARTICLES: `${api}/api-2/online/:pageId/`,
    API_DETAIL_DATA: `${api}/api-2/detail/:articleId/`,
    API_SEARCH_PAGE_ARTICLES: `${api}/api-2/search/`,
    API_USER_REG: `${api}/activity/user/registration/`,
    API_USER_UPDATE: `${api}/activity/user/update/`,
    PAGE_REQUIREMENT: `${api}/api-2/requirement/`,
    API_REGISTRATION: `${api}/api-2/requirement/`,
    API_TAG: `${api}/api-2/tag/:tags/`,
    API_AUTHOR: `${api}/api-2/author/`,
    API_NOTIFICATION: `${apiNotifi}/api/notification-data/`,
    MORE_CATEGORE_DATA: `${api}/api-2/article/more-category/data/`,

    API_COMMENT: `${api}/api-2/article/comment/`,
    API_LIKE: `${api}/api-2/article/like/`,
    API_SAVE: `${api}/api-2/article/save/`,
    API_USER_SAVE: `${api}/activity/user/article/save/`,
    API_USER_COMMENTS: `${api}/activity/user/article/comments/`,
    API_NOTI_READ: `${api}/activity/user/notification-read/`,
    API_NOTI_READ_SET: `${api}/activity/user/notification-read/set/`
};

export default ConfigApi;
