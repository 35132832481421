/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import HeaderImg from '../../../../assets/images/football-banner-header.svg';
import Trofy from '../../../../assets/images/footballTrofy.svg';
import Blinking from '../../../../assets/images/Live-blinking.gif';
import ConvertStringBn from '../../../utils/ConvertStringBn';
import InitInfo from '../../../utils/InitInfo';

export function TimeCal({ mTime, amPm }) {
    if (mTime.toString() === '4') {
        return 'বিকাল ৪ টা ';
    }
    if (mTime.toString() === '7') {
        return 'সন্ধ্যা ৭ টা';
    }
    if (mTime.toString() === '10') {
        return 'রাত ১০ টা';
    }
    if (mTime.toString() === '9') {
        return 'রাত ৯ টা';
    }
    if (mTime.toString() === '1') {
        return 'রাত ১ টা ';
    }
    return <span>{ConvertStringBn(`${mTime} ${amPm}`)}</span>;
}
export function TimestampToTime({ time }) {
    const newDate = new Date(time * 1000);
    let hours = newDate.getHours();
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12;
    hours = hours.toString();
    const amPm = newDate.toLocaleTimeString('en-BD').split(' ')[1];

    return (
        <span>
            <TimeCal mTime={hours} amPm={amPm} />
        </span>
    );
}

export function CurrentStatus({ matchTime }) {
    const timeStamp = new Date().getTime() / 1000;
    if (matchTime + 6900 < timeStamp) {
        return <p>ফলাফল</p>;
    }

    if (matchTime < timeStamp && matchTime + 6900 > timeStamp) {
        return (
            <p>
                {/* <span className="live-bull">&bull;</span> */}
                <span>
                    <img
                        src={Blinking}
                        alt="&bull;"
                        style={{ width: 10, marginRight: 4, marginBottom: 3 }}
                    />
                    লাইভ
                </span>
            </p>
        );
    }

    return (
        <p>
            <TimestampToTime time={matchTime} />
        </p>
    );
}

export function CurrentStatusIsResult({ matchTime }) {
    const timeStamp = new Date().getTime() / 1000;
    if (matchTime + 6000 < timeStamp) {
        return 1;
    }

    if (matchTime < timeStamp && matchTime + 6000 > timeStamp) {
        return 0;
    }

    return (
        <p>
            <TimestampToTime time={matchTime} />
        </p>
    );
}
function FootballBanner() {
    const { worldCupAr } = InitInfo();

    const totalArray = worldCupAr?.length;
    console.log(totalArray);
    return (
        <div className="football-banner">
            <div
                className="football-banner-header"
                onClick={() => window.open('/online/football/', '_blank')}
            >
                <img src={HeaderImg} alt="headerImg" />
            </div>
            <div className="football-banner-menu">
                <ul>
                    <li onClick={() => window.open('/fixtures/', '_blank')}>
                        <img src={Trofy} alt="" /> বিশ্বকাপের সূচি
                    </li>
                    <li onClick={() => window.open('/point-table/', '_blank')}>
                        <img src={Trofy} alt="" /> পয়েন্ট টেবিল
                    </li>
                </ul>
            </div>
            <div className="football-banner-divider">
                <div />
            </div>
            <div className="football-banner-title">আজকের খেলা</div>
            <div className="football-banner-body">
                {worldCupAr?.map((row) => (
                    <div
                        className={`item ${totalArray === 3 ? 'item-c' : null}`}
                        style={{
                            width: totalArray < 3 ? '100%' : '50%',
                            borderRight: totalArray < 3 ? 'unset' : ''
                        }}
                        key={row?.sl}
                    >
                        <div
                            className="item-country"
                            style={{
                                width: totalArray < 3 ? '18%' : '33%'
                            }}
                        >
                            <div
                                className="item-country-img"
                                // style={{
                                //     width: totalArray === 1 ? '85px' : '40px',
                                //     height: totalArray === 1 ? '85px' : '40px'
                                // }}
                            >
                                <img src={`https://bahannonews.com/${row?.team_1_img}`} alt="" />
                            </div>
                            <p>{row?.team_1?.substring(0, 3).toUpperCase()}</p>
                        </div>
                        <div className="item-result-info">
                            <div
                                className="item-result"
                                // style={{
                                //     width: totalArray === 1 ? '80%' : '100%',
                                //     height: totalArray === 1 ? '56px' : '26px'
                                // }}
                            >
                                {ConvertStringBn(`${row?.team_1_goal}`)} :{' '}
                                {ConvertStringBn(`${row?.team_2_goal}`)}
                            </div>
                            <CurrentStatus matchTime={row?.schedule} />
                        </div>
                        <div
                            className="item-country"
                            style={{
                                width: totalArray < 3 ? '18%' : '33%'
                            }}
                        >
                            <div
                                className="item-country-img"
                                // style={{
                                //     width: totalArray === 1 ? '85px' : '40px',
                                //     height: totalArray === 1 ? '85px' : '40px'
                                // }}
                            >
                                <img src={`https://bahannonews.com/${row?.team_2_img}`} alt="" />
                            </div>
                            <p>{row?.team_2?.substring(0, 3).toUpperCase()}</p>
                        </div>
                    </div>
                ))}
                {totalArray < 3 ? null : <div className="gapDesign" />}
            </div>
            <div className="football-banner-footer">
                <a
                    href="https://www.crichd.live/fifa-world-cup-live-stream-3-watch-online"
                    target="_blank"
                    rel="noreferrer"
                >
                    লাইভ দেখতে ক্লিক করুন
                </a>
            </div>
        </div>
    );
}

export default FootballBanner;
