import ConfigApi from '../../configs/ConfigApi';
import store from '../store';
import AxiosAuth from '../utils/AxiosAuth';
import { setActiveMenu } from '../utils/SetStoreParameters';

function initDataSetup(payload) {
    return {
        type: 'INIT_DATA_SET',
        payload
    };
}

function siteDataSetup(payload) {
    return {
        type: 'SITE_DATA_SET',
        payload
    };
}

export default function AppInitDispatch(dispatch, params) {
    return new Promise((resolve, reject) => {
        if (!store.getState().initData?.initId || params?.reset === true) {
            AxiosAuth.post(ConfigApi.API_APP_INIT, params)
                .then((response) => {
                    // Init Info
                    dispatch(initDataSetup(response.data.initData));
                    dispatch(siteDataSetup(response.data.siteData));
                    dispatch(setActiveMenu('/'));

                    localStorage.setItem('init-id', response.data.initData.initId);
                    localStorage.setItem('showTopAd', 'block');
                    localStorage.setItem('showBottomAd', true);
                    resolve(true);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        } else {
            resolve(true);
        }
    });
}
