import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
// import openImg from '../../assets/ads/welcome-bijoy-dibosh-21.jpg';
// import openImg from '../../assets/ads/welcome-bijoy-dibosh-21.jpg';
import InitInfo from '../utils/InitInfo';

function WelcomeModalMain({ show, handleHideModal, wcBanner }) {
    if (localStorage.getItem('welcome') === 'true') {
        return null;
    }
    return (
        <Modal
            size="lg"
            className="welcome-modal"
            show={show}
            onHide={handleHideModal}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            backdrop
        >
            <Modal.Header className="welcome-modal-header" closeButton />
            <Modal.Body closeButton>
                <div className="welcome-image d-none d-lg-block d-xl-block d-md-block">
                    <img src={`https://www.bahannonews.com/${wcBanner.image}`} alt="Welcome" />
                </div>
                <div className="welcome-image d-block  d-lg-none d-xl-none d-md-none">
                    <img
                        src={`https://www.bahannonews.com/${
                            wcBanner.image_mb ? wcBanner.image_mb : wcBanner.image
                        }`}
                        alt="Welcome"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

function WelcomeModal({ show, localStorageName = 'welcome-2' }) {
    const [showModal, setShowModal] = useState(show);
    const { wcBanner } = InitInfo();
    const [initialTime, setInitialTime] = useState(wcBanner.duration);
    const [startTimer, setStartTimer] = useState(true);
    const welcome = localStorageName ? localStorage.getItem(localStorageName) : null;
    const handleHideModal = () => {
        setShowModal(false);

        if (localStorageName !== null) {
            localStorage.setItem(localStorageName, 'true');
        }
    };

    useEffect(() => {
        if (initialTime > 0) {
            setTimeout(() => {
                setInitialTime(initialTime - 1);
            }, 1000);
        }

        if (initialTime === 0 && startTimer) {
            setStartTimer(false);
            setShowModal(false);
        }
    }, [initialTime, startTimer]);

    if (!show) {
        return null;
    }
    return (
        <WelcomeModalMain
            show={showModal && welcome !== 'true'}
            handleHideModal={handleHideModal}
            wcBanner={wcBanner}
        />
    );
}

export default WelcomeModal;
