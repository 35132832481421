import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'react-bootstrap-carousel/dist/react-bootstrap-carousel.css';
import { Provider } from 'react-redux';
import '../assets/css/style.css';
import '../assets/css/style.responsive.css';
import '../assets/css/template.css';
import '../assets/css/top-box.css';
import '../assets/css/top-option.css';
import '../assets/css/user.css';
import RouteHandler from './RouteHandler';
import TemplateProvider from './contexts/TemplateProvider';
import store from './store';

function App() {
    return (
        <TemplateProvider>
            <Provider store={store}>
                <RouteHandler />
            </Provider>
        </TemplateProvider>
    );
}

export default App;
