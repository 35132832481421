import ConvertStringBn from './ConvertStringBn';

function TimeShow({ time }) {
    const days = {
        Sun: 'Sunday',
        Mon: 'Monday',
        Tue: 'Tuesday',
        Wed: 'Wednesday',
        Thu: 'Thursday',
        Fri: 'Friday',
        Sat: 'Saturday'
    };
    const newDate = new Date(time * 1000);
    const [day, month, date, year] = newDate.toDateString().split(' ');
    // const minutes = newDate.getMinutes().toString().padStart(2, '0');
    let hours = newDate.getHours();
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12;
    hours = hours.toString().padStart(2, '0');
    // const amPm = newDate.toLocaleTimeString('en-BD').split(' ')[1];

    if (!time) {
        return null;
    }

    return (
        <span>{ConvertStringBn(`${days[day]}, ${date} ${month} ${year}`)}</span>
        // <p>{ConvertStringBn(`${day}, ${date} ${month} ${year}, ${hours}:${minutes} ${amPm}`)}</p>
    );
}
export default TimeShow;
