/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-undef */
import '../../assets/css/placeholder.css';
import DetailsPlaceholderImg from '../../assets/images/details-placeholder.png';
import DetailsPlaceholderImg2 from '../../assets/images/details-placeholder2.png';

export default function IndexPagePlaceholder() {
    return (
        <div>
            {/* Body Section */}

            <div className="container">
                <div className="row mt-5">
                    <div className="col col-840">
                        <div className="row">
                            <div className="col-12 col-510 ">
                                <div className="hero-news">
                                    <div className="hero-news-body link-area">
                                        <img src={DetailsPlaceholderImg} alt="Place" />
                                        <div className="hero-news-body-text">
                                            <div className="hero-news-body-title link-title">
                                                <div
                                                    style={{
                                                        height: 20,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                            </div>

                                            <div className="hero-news-body-detail">
                                                <div
                                                    style={{
                                                        height: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        height: 10,
                                                        marginTop: 4,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        height: 10,
                                                        marginTop: 4,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 col-300 d-none d-lg-block d-xl-block">
                                <div className="updated-news pt-0">
                                    <div className="update-news-title">
                                        <button type="button" />
                                        <button type="button" />
                                    </div>
                                    <div className="display-show">
                                        <div className="update-news-body link-area">
                                            <div className="update-news-body-img">
                                                <img src={DetailsPlaceholderImg2} alt="im" />
                                            </div>
                                            <div className="update-news-body-title link-title">
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="update-news-body link-area">
                                            <div className="update-news-body-img">
                                                <img src={DetailsPlaceholderImg2} alt="im" />
                                            </div>
                                            <div className="update-news-body-title link-title">
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="update-news-body link-area">
                                            <div className="update-news-body-img">
                                                <img src={DetailsPlaceholderImg2} alt="im" />
                                            </div>
                                            <div className="update-news-body-title link-title">
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="update-news-body link-area">
                                            <div className="update-news-body-img">
                                                <img src={DetailsPlaceholderImg2} alt="im" />
                                            </div>
                                            <div className="update-news-body-title link-title">
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="update-news-body link-area">
                                            <div className="update-news-body-img">
                                                <img src={DetailsPlaceholderImg2} alt="im" />
                                            </div>
                                            <div className="update-news-body-title link-title">
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        marginTop: 8,
                                                        height: 10,
                                                        marginLeft: 10,
                                                        backgroundColor: '#F7F9FD'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ margin: '15px' }}>
                            <div className="row">
                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                                    <div className="hero-news-short">
                                        <div className="hero-news-short-img">
                                            <img src={DetailsPlaceholderImg2} alt="news" />
                                        </div>
                                        <div className="hero-news-short-title">
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10
                                                }}
                                            />
                                            <div
                                                className="details-report-time"
                                                style={{
                                                    backgroundColor: '#E8F1FE',
                                                    height: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-300-1">
                        <div className="sidebar-add-one">
                            <img src={DetailsPlaceholderImg2} alt="Add One" />
                        </div>
                        <div className="sidebar-category-news">
                            <div className="sidebar-category-news-title" />
                            <div className="side-news-short">
                                <div className="side-news-short-img">
                                    <img src={DetailsPlaceholderImg2} alt="news" />
                                </div>
                                <div className="side-news-short-title">
                                    <div
                                        className="details-report-time"
                                        style={{
                                            backgroundColor: '#E8F1FE',
                                            height: 10
                                        }}
                                    />
                                    <div
                                        className="details-report-time"
                                        style={{
                                            backgroundColor: '#E8F1FE',
                                            height: 10,
                                            marginTop: 10
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
