import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useLastUpdate } from '../../../contexts/LastUpdateProvider';
import PageProperties from '../../../models/PageProperties';
import DetailsPlaceholder from '../../../placeholders/DetailsPlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import { setActiveMenu } from '../../../utils/SetStoreParameters';
import DefaultSidebarSection from '../section/DefaultSidebarSection';
import FlowDetailSection from '../section/FlowDetailSection';
import FlowSidebarSection from '../section/FlowSidebarSection';
import NotFoundSection from '../section/NotFoundSection';

function DetailPageControl() {
    const dispatch = useDispatch();

    const { articleId } = useParams();
    const [articleData, setArticleData] = useState(null);
    const article = articleData?.articleDetailsFlow || {};
    const article2 = useLastUpdate().article;
    const page = article?.page || '/';
    const defaultNews = articleData?.articleDetailsFlow?.filter(
        (rowFil) => rowFil?.is_default === 1
    )[0];

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,

                articleDetailsFlow: articleId
            }
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [articleId, article2]);

    useEffect(() => {
        const pageId = page || 'na';
        if (pageId) {
            dispatch(setActiveMenu(pageId));
            PageProperties(article?.title || '');
        }
    }, [page, article.title, dispatch]);

    if (articleData === null || articleData?.articleDetailsFlow.length < 1) {
        return <DetailsPlaceholder />;
    }

    return (
        <div className="container mb-4">
            <div className="row mt-4">
                <div className="col col-840">
                    <div className="row">
                        <div className="col-12">
                            <div className="flow-details-news-title">
                                <h1>{defaultNews?.flow_title}</h1>
                                <img
                                    src={`https://bahannonews.com/${defaultNews?.flow_banner}`}
                                    alt={defaultNews?.flow_title}
                                />
                            </div>
                        </div>
                        <FlowSidebarSection latestArticles={articleData?.articleDetailsFlow} />
                        <div className="col col-840">
                            {articleData.recent ? (
                                <FlowDetailSection article={articleData?.articleDetailsFlow} />
                            ) : (
                                <NotFoundSection />
                            )}
                        </div>
                    </div>
                </div>
                <DefaultSidebarSection
                    latestArticles={articleData?.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function DetailPageFlow() {
    const { articleId } = useParams();
    return <DetailPageControl key={articleId} />;
}
