import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import PageProperties from '../../../models/PageProperties';
import AxiosAuth from '../../../utils/AxiosAuth';
import BrowserInfo from '../../../utils/BrowserInfo';
import ConvertStringBn from '../../../utils/ConvertStringBn';

export function FixPageTitle({ isTab, setIsTab }) {
    const { pathname } = useLocation();
    console.log(isTab);
    return (
        <>
            <div className="football-fixture-title" />
            <div className="football-fixture-breadcumb">
                <ul>
                    <li>
                        <Link to="/online/football/">কাতার বিশ্বকাপ</Link>
                    </li>
                    <li style={{ color: pathname === '/fixtures/' ? '#8F0F37' : '' }}>
                        <Link
                            style={{ color: pathname === '/fixtures/' ? '#8F0F37' : '' }}
                            to="/fixtures/"
                        >
                            সূচি
                        </Link>
                    </li>
                    <li style={{ color: pathname === '/point-table/' ? '#8F0F37' : '' }}>
                        <Link
                            style={{ color: pathname === '/point-table/' ? '#8F0F37' : '' }}
                            to="/point-table/"
                        >
                            পয়েন্ট টেবিল
                        </Link>
                    </li>
                </ul>
            </div>
            {isTab !== null ? (
                <div className="football-fixture-tab">
                    <button
                        type="button"
                        className={isTab === 'fixer' ? 'active' : null}
                        onClick={() => setIsTab('fixer')}
                    >
                        সূচি
                    </button>
                    <button
                        type="button"
                        className={isTab === 'result' ? 'active' : null}
                        onClick={() => setIsTab('result')}
                    >
                        ফলাফল
                    </button>
                </div>
            ) : null}
        </>
    );
}

function Desktop({ fixtureAr }) {
    return (
        <>
            {Object.keys(fixtureAr)?.map((row) => (
                <div className="football-point-tbl" key={row}>
                    <div className="football-point-tbl-title">
                        গ্রুপ <span>{row}</span>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>দল</th>
                                <th>ম্যাচ</th>
                                <th>জয়</th>
                                <th>ড্র</th>
                                <th>হার</th>
                                <th>পক্ষে</th>
                                <th>বিপক্ষে</th>
                                <th>ব্যবধান</th>
                                <th>পয়েন্ট</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(fixtureAr[row])
                                ?.sort((a, b) => b.point - a.point)
                                ?.map((rowAr) => (
                                    <tr key={rowAr?.sl}>
                                        <td>
                                            <img
                                                src={`https://bahannonews.com/${rowAr?.team_img}`}
                                                alt={rowAr?.team_bn}
                                            />
                                            {rowAr?.team_bn}
                                        </td>
                                        <td>{ConvertStringBn(`${rowAr?.match}`)}</td>
                                        <td>{ConvertStringBn(`${rowAr?.win}`)}</td>
                                        <td>{ConvertStringBn(`${rowAr?.draw}`)}</td>
                                        <td>{ConvertStringBn(`${rowAr?.lost}`)}</td>
                                        <td>{ConvertStringBn(`${rowAr?.favor}`)}</td>
                                        <td>{ConvertStringBn(`${rowAr?.against}`)}</td>
                                        <td>{ConvertStringBn(`${rowAr?.distance}`)}</td>
                                        <td>{ConvertStringBn(`${rowAr?.point}`)}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </>
    );
}
function Mobile({ fixtureAr }) {
    return (
        <>
            {Object.keys(fixtureAr)?.map((row) => (
                <div className="football-point-tbl" key={row}>
                    <div className="football-point-tbl-title">
                        গ্রুপ <span>{row}</span>
                    </div>
                    {Object.values(fixtureAr[row])?.map((rowAr) => (
                        <table key={rowAr?.sl}>
                            <thead>
                                <tr>
                                    <th colSpan={5}>
                                        <img
                                            src={`https://bahannonews.com/${rowAr?.team_img}`}
                                            alt=""
                                            className="football-point-tbl-title-img"
                                        />
                                        {rowAr?.team_bn}
                                    </th>

                                    <th colSpan={2}>
                                        পয়েন্ট: {ConvertStringBn(`${rowAr?.point}`)}
                                    </th>
                                </tr>
                            </thead>
                            <thead className="football-point-tbl-thead">
                                <tr>
                                    <th>ম্যাচ</th>
                                    <th>জয়</th>
                                    <th>ড্র</th>
                                    <th>হার</th>
                                    <th>পক্ষে</th>
                                    <th>বিপক্ষে</th>
                                    <th>ব্যবধান</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ fontSize: 16 }}>
                                        {ConvertStringBn(`${rowAr?.match}`)}
                                    </td>
                                    <td>{ConvertStringBn(`${rowAr?.win}`)}</td>
                                    <td>{ConvertStringBn(`${rowAr?.draw}`)}</td>
                                    <td>{ConvertStringBn(`${rowAr?.lost}`)}</td>
                                    <td>{ConvertStringBn(`${rowAr?.favor}`)}</td>
                                    <td>{ConvertStringBn(`${rowAr?.against}`)}</td>
                                    <td>{ConvertStringBn(`${rowAr?.distance}`)}</td>
                                </tr>
                            </tbody>
                        </table>
                    ))}
                </div>
            ))}
        </>
    );
}
function PointTablePage() {
    const [fixtureAr, setFixtureAr] = useState([]);
    const page = 'pointTable';
    const browserData = BrowserInfo();
    const [isTab, setIsTab] = useState(null);

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                [page]: 220
            }
        }).then(({ data }) => {
            setFixtureAr(data.articles);
        });
    }, []);

    useEffect(() => {
        PageProperties('বিশ্বকাপ ফুটবল পয়েন্ট টেবিল ২০২২');
    }, []);

    if (
        browserData.sizeName === 'bs-sm' ||
        browserData.sizeName === 'bs-xs' ||
        browserData.sizeName === 'bs-xxs'
    ) {
        return (
            <div className="container">
                <div className="football-point">
                    <FixPageTitle isTab={isTab} setIsTab={setIsTab} />
                    <Mobile fixtureAr={fixtureAr} />;
                </div>
            </div>
        );
    }
    return (
        <div className="container">
            <div className="football-point">
                <FixPageTitle isTab={isTab} setIsTab={setIsTab} />
                <Desktop fixtureAr={fixtureAr} />;
            </div>
        </div>
    );
}

export default PointTablePage;
