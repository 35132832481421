import { useEffect, useState } from 'react';
import BrowserInfo from './BrowserInfo';
import InitInfo from './InitInfo';

let loadPageOn = 300;

// const loadPageOn = 300; // px

function MultiPageLoader({ defPage, pages = [] }) {
    // const [index, setIndex] = useState(0);
    const [loadedPages, setLoadedPage] = useState([defPage]);
    const browserData = BrowserInfo();

    if (browserData.sizeName === 'bs-xs') {
        loadPageOn = 3100; // px
    }
    if (browserData.sizeName === 'bs-md') {
        loadPageOn = 700; // px
    }
    if (browserData.sizeName === 'bs-sm') {
        loadPageOn = 3100; // px
    }
    if (browserData.sizeName === 'bs-xxs') {
        loadPageOn = 3100; // px
    }

    useEffect(() => {
        const loadPage = () => {
            setLoadedPage((val) => {
                const newPage = pages[val.length - 1] || null;
                if (newPage) {
                    val.push(newPage);
                }
                return [...val];
            });
        };

        const onScroll = (ev) => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.offsetHeight;
            const topPosition = ev?.target?.documentElement?.scrollTop || 0;

            if (loadPageOn + windowHeight + topPosition > documentHeight) {
                const { loadingStatus } = InitInfo();
                if (loadingStatus !== true) {
                    loadPage();
                }
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [pages]);

    return (
        <div>
            {loadedPages.map((page) => (
                <div key={page?.props?.keyName}>{page}</div>
            ))}
        </div>
    );
}

export default MultiPageLoader;
