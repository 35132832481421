import React from 'react';

import TimestampToDate from '../../../models/TimestampToDate';

function FlowSidebarSection({ latestArticles }) {
    return (
        <>
            <div
                className="col col-300-1 d-none d-lg-block d-xl-block d-md-block not-print-class"
                style={{ position: 'relative' }}
            >
                <div className="flow-sidebar-nav">
                    <div className="flow-sidebar-title">মূল বিষয়গুলি</div>
                    <div id="content">
                        <ul className="timeline">
                            {Object.values(latestArticles)?.map((row) => (
                                <li className="event" key={row.sl}>
                                    <a href={`#${row.sl}`}>
                                        <p>
                                            <TimestampToDate time={row.time_published} />
                                        </p>
                                        <h3>{row.title}</h3>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FlowSidebarSection;
