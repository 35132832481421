import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useLastUpdate } from '../../../contexts/LastUpdateProvider';
import PageProperties from '../../../models/PageProperties';
import PickCategoryByPageId from '../../../models/PickCategoryByPageId';
import CategoryPlaceholder from '../../../placeholders/CategoryPlaceholder';
import AxiosAuth from '../../../utils/AxiosAuth';
import { setActiveMenu } from '../../../utils/SetStoreParameters';
import CategorySection01 from '../section/CategorySection01';
import DefaultSidebarSection from '../section/DefaultSidebarSection';

function CategoryPageControl({ pageId }) {
    const dispatch = useDispatch();
    const [articleData, setArticleData] = useState(null);
    const page = `category-online/${pageId}`;
    const page2 = `category-online/football`;
    const { article } = useLastUpdate();

    useEffect(() => {
        AxiosAuth.post(ConfigApi.PAGE_REQUIREMENT, {
            require: {
                recent: 5,

                'category-online/sports': 5,
                'category-online/entertainment': 5,
                'category-online/jobs': 5,
                [page2]: 50,
                [page]: 220
            }
        }).then(({ data }) => {
            setArticleData(data.articles);
        });
    }, [page, article, page2]);

    useEffect(() => {
        dispatch(setActiveMenu(pageId));
        PageProperties(PickCategoryByPageId('online', pageId).title);
    }, [dispatch, pageId]);

    if (articleData === null) {
        return <CategoryPlaceholder />;
    }

    return (
        <div className="container">
            <div className="row mt-4">
                <CategorySection01
                    category="online"
                    pageId={pageId}
                    articles={
                        pageId !== 'sports'
                            ? articleData[page]
                            : [...articleData[page], ...articleData[page2]]
                    }
                    latestArticles={articleData.recent}
                />

                <DefaultSidebarSection
                    latestArticles={articleData.recent}
                    articles1={articleData['category-online/sports']}
                    title1="খেলা"
                    articles2={articleData['category-online/entertainment']}
                    title2="বিনোদন"
                    articles3={articleData['category-online/jobs']}
                    title3="চাকরি"
                />
            </div>
        </div>
    );
}

export default function CategoryPage() {
    const { pageId } = useParams();

    return <CategoryPageControl key={pageId} pageId={pageId} />;
}
