/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import SearchIcon from '../../../../assets/images/search-solid.svg';
import BoxNews from '../components/BoxNews';

export default function TagSection({ articles, q }) {
    const [moreCount, setMoreCount] = useState(30);

    const handleMoreCount = () => {
        setMoreCount(moreCount + 10);
    };
    const totalArticle = articles.length;
    const findHash = q.split('')[0];
    return (
        <div className="col col-840">
            <div className="row">
                {totalArticle > 0 ? (
                    <div className="col-12">
                        <div className="home-category-news-title">
                            <h5>
                                {findHash === '#' ? '' : '#'}
                                {q}
                            </h5>
                        </div>
                    </div>
                ) : null}

                {Object.values(articles)
                    .slice(0, `${moreCount}`)
                    .map((art) => (
                        <div className="col-lg-4 col-xl-4 col-12">
                            <BoxNews article={art} />
                        </div>
                    ))}

                {totalArticle <= 0 ? (
                    <div className="search-data-n-found">
                        <div className="search-data-n-found-h">
                            অনুসন্ধানের কোনো ফলাফল পাওয়া যায়নি
                        </div>
                        <div className="search-data-n-found-b">
                            আমরা দুঃখিত. আমরা আপনার অনুসন্ধান শব্দটির জন্য কোন মিল খুঁজে পাচ্ছি না।
                        </div>
                        <div className="search-data-n-found-i">
                            <img src={SearchIcon} style={{ width: 50 }} alt="Search" />
                        </div>
                    </div>
                ) : (
                    <div className="col-12">
                        <div className="category-news-more-btn" onClick={handleMoreCount}>
                            <Button>{totalArticle <= moreCount ? 'শেষ ' : 'আরও'}</Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
