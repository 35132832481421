import { Ads14Full } from '../../../components/Ads';
import BrowserInfo from '../../../utils/BrowserInfo';
import BottomNewsList from '../components/BottomNewsList';

function HomeSection06MainLg({ articles1, title1, articles2, title2, articles4, title4 }) {
    return (
        <div className="container">
            <div className="row">
                <BottomNewsList title={title1} articles={articles1} />
                <BottomNewsList title={title2} articles={articles2} />
                <BottomNewsList title={title4} articles={articles4} />
            </div>
        </div>
    );
}
function HomeSection06Main({
    articles1,
    title1,
    articles2,
    title2,
    articles3,
    title3,
    articles4,
    title4
}) {
    return (
        <div className="container">
            <div className="row">
                <BottomNewsList title={title1} articles={articles1} />
                <BottomNewsList title={title2} articles={articles2} />

                <div className="col-12 d-block d-lg-none d-xl-none d-md-none">
                    <Ads14Full show mode="placeholder" />
                </div>

                <BottomNewsList title={title3} articles={articles3} />
                <BottomNewsList title={title4} articles={articles4} />
            </div>
        </div>
    );
}

function HomeSection06({
    articles1,
    title1,
    articles2,
    title2,
    articles3,
    title3,
    articles4,
    title4
}) {
    const { sizeName } = BrowserInfo();
    if (sizeName === 'bs-lg') {
        return (
            <HomeSection06MainLg
                articles1={articles1}
                title1={title1}
                articles2={articles2}
                title2={title2}
                articles3={articles3}
                title3={title3}
                articles4={articles4}
                title4={title4}
            />
        );
    }

    return (
        <HomeSection06Main
            articles1={articles1}
            title1={title1}
            articles2={articles2}
            title2={title2}
            articles3={articles3}
            title3={title3}
            articles4={articles4}
            title4={title4}
        />
    );
}

export default HomeSection06;
