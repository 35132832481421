import { Link } from 'react-router-dom';
import PickCategoryByPageId from '../../../models/PickCategoryByPageId';
import BoxNewsRelated from '../components/BoxNewsRelated';

function DetailsSection02({ article, speArticleAr = [] }) {
    // const speArticleArLead = Object.values(speArticleAr)[1];
    const cat = PickCategoryByPageId('online', article.page);

    return (
        <div
            className="row not-print-class"
            style={{ display: speArticleAr.length === 0 ? 'none' : '' }}
        >
            {/* <div className="col-12">
                <Ads14Full show mode="placeholder" />
            </div> */}

            <div className="col-12 details-realted-news-title">এই সম্পর্কিত আরও সাংবাদ </div>
            <div className="col-12">
                <div className="row">
                    {Object.values(speArticleAr)[0] ? (
                        <div className="col-lg-3 col-xl-3 col-6">
                            <BoxNewsRelated article={Object.values(speArticleAr)[0]} />
                        </div>
                    ) : (
                        ''
                    )}
                    {Object.values(speArticleAr)[1] ? (
                        <div className="col-lg-3 col-xl-3 col-6">
                            <BoxNewsRelated article={Object.values(speArticleAr)[1]} />
                        </div>
                    ) : (
                        ''
                    )}
                    {Object.values(speArticleAr)[2] ? (
                        <div className="col-lg-3 col-xl-3 col-6">
                            <BoxNewsRelated article={Object.values(speArticleAr)[2]} />
                        </div>
                    ) : (
                        ''
                    )}
                    {Object.values(speArticleAr)[3] ? (
                        <div className="col-lg-3 col-xl-3 col-6">
                            <BoxNewsRelated article={Object.values(speArticleAr)[3]} />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="link-area">
                    <Link to={cat.url || '/'} style={{ float: 'right' }} className="link-title">
                        আরও...
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default DetailsSection02;
