import placeholderImage from '../../assets/images/placeholder-image.svg';

function Iopt(url, maxWidth) {
    if (!url) {
        return placeholderImage;
    }

    const encodedUrl = btoa(unescape(encodeURIComponent(url)))
        .split('/')
        .join('-');

    if (maxWidth) {
        return `https://www.bahannonews.com/img/e/${encodedUrl},${maxWidth}.jpg`;
    }

    return `https://www.bahannonews.com/img/e/${encodedUrl}.jpg`;
}

export default Iopt;
