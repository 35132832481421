/* eslint-disable react/jsx-no-bind */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import AddPdf from '../../../../assets/ads/Ad_Proposal.pdf';
import PageProperties from '../../../models/PageProperties';
import { setActiveMenu } from '../../../utils/SetStoreParameters';
// Core viewer
// Plugins

export default function AdvertisePage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenu('/'));
    }, [dispatch]);

    PageProperties('Advertisements');

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 mt-3 mb-3">
                    <div className="home-category-news-title" style={{ width: '100%' }}>
                        <div className="home-category-news-title-sub" style={{ width: 150 }}>
                            <Link to="privacy">বিজ্ঞাপন</Link>
                        </div>
                    </div>
                    <div className="details-news-body" style={{ width: '100%' }}>
                        <div className="details-news-body-detail">
                            <object
                                data={AddPdf}
                                type="application/pdf"
                                style={{ width: '100%', minHeight: 1000 }}
                            >
                                <div>No online PDF viewer installed</div>
                            </object>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
