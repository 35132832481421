import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PageProperties from '../../../models/PageProperties';
import { setActiveMenu } from '../../../utils/SetStoreParameters';

export default function PrivacyPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenu('/'));
        PageProperties('গোপনীয়তা নীতি');
    }, [dispatch]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 mt-3 mb-3">
                    <div className="home-category-news-title" style={{ width: '100%' }}>
                        <div className="home-category-news-title-sub" style={{ width: 175 }}>
                            <Link to="privacy">গোপনীয়তা নীতি</Link>
                        </div>
                    </div>
                    <div className="details-news-body" style={{ width: '100%' }}>
                        <div className="details-news-body-detail" style={{ minHeight: 500 }}>
                            <p>
                                All contents, including news, quotes, data, photos, videos, graphics
                                and other information <br />
                                on
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>
                                site is provided for your personal information only. It is not
                                placed for any sort of commercial use. <br />
                                The information collected on this site are for the exclusive use of
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>
                                and cannot be disclosed or sold to third parties. The readers of
                                this site have the rights of access for reading only.
                                <br />{' '}
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>
                                implements technology and security features in order to safeguard
                                the privacy of its readers from loss, unauthorized access or
                                improper use.
                                <br />
                                Users of the site are invited to inform
                                <Link to="/" className="font-weight-bold">
                                    bahannonews.com
                                </Link>
                                services on any dysfunctions of the site in the light of privacy
                                rights. If you need any more data or have any questions about our
                                privacy policy, please contact us by email at bahannonews@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
