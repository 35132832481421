import React from 'react';
import { Link } from 'react-router-dom';
import { XlHome09 } from '../../../components/AdManagerXl';
import { Ads18 } from '../../../components/Ads';
import HeroNews from '../../../components/HeroNews';
import UpdatedNewsListStyle2 from '../../../components/UpdatedNewsListStyle2';
import BrowserInfo from '../../../utils/BrowserInfo';
import SideBarNewsList from '../components/SideBarNewsList';

function HomeSection02MainMd({ nationalArticleAr }) {
    const nationalArticleArLead = nationalArticleAr[0];

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="home-category-news-title">
                        <div className="home-category-news-title-sub">
                            <Link to="/online/national/">জাতীয়</Link>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-510">
                    <HeroNews article={nationalArticleArLead} />
                </div>

                <div className="col-4 col-300">
                    <UpdatedNewsListStyle2
                        pageId="national"
                        articleAr={nationalArticleAr}
                        maxArticle={6}
                    />
                </div>
            </div>
        </div>
    );
}

function HomeSection02MainLg({ nationalArticleAr }) {
    const nationalArticleArLead = nationalArticleAr[0];

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="home-category-news-title">
                        <div className="home-category-news-title-sub">
                            <Link to="/online/national/">জাতীয়</Link>
                        </div>
                    </div>
                </div>

                <div className="col col-840">
                    <HeroNews article={nationalArticleArLead} />
                </div>

                <div className="col col-300-1">
                    <UpdatedNewsListStyle2
                        pageId="national"
                        articleAr={nationalArticleAr}
                        maxArticle={7}
                    />
                </div>
            </div>
        </div>
    );
}

function HomeSection02Main({ nationalArticleAr, economicsArticleAr }) {
    const nationalArticleArLead = nationalArticleAr[0];

    return (
        <div className="container">
            <div className="row">
                <div className="col col-840">
                    <div className="row">
                        <div className="col-12">
                            <div className="home-category-news-title">
                                <div className="home-category-news-title-sub">
                                    <Link to="/online/national/">জাতীয়</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-510">
                            <HeroNews article={nationalArticleArLead} />
                        </div>

                        <div className="col-4 col-300">
                            <UpdatedNewsListStyle2
                                pageId="national"
                                articleAr={nationalArticleAr}
                                maxArticle={7}
                            />
                        </div>
                    </div>
                </div>
                <div className="col col-300-1">
                    <SideBarNewsList
                        title="রাজনীতি"
                        articleAr={economicsArticleAr}
                        maxArticle={6}
                    />
                </div>
                <div
                    className="col-12 d-none d-lg-block d-xl-block"
                    style={{ paddingLeft: 286, paddingRight: 286 }}
                >
                    {/* <Ads11 show mode="placeholder" /> */}
                    <XlHome09 />
                </div>

                <div className="col-12 d-lg-none d-block ">
                    <Ads18 show mode="placeholder" />
                </div>
            </div>
        </div>
    );
}

function HomeSection02({ nationalArticleAr, economicsArticleAr }) {
    const browserInfo = BrowserInfo();

    if (browserInfo.sizeName === 'bs-lg') {
        return (
            <HomeSection02MainLg
                nationalArticleAr={nationalArticleAr}
                economicsArticleAr={economicsArticleAr}
            />
        );
    }
    if (browserInfo.sizeName === 'bs-md') {
        return (
            <HomeSection02MainMd
                nationalArticleAr={nationalArticleAr}
                economicsArticleAr={economicsArticleAr}
            />
        );
    }

    return (
        <HomeSection02Main
            nationalArticleAr={nationalArticleAr}
            economicsArticleAr={economicsArticleAr}
        />
    );
}

export default HomeSection02;
