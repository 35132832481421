import { useLayoutEffect, useState } from 'react';

const screenSizeList = [
    320, // 100% - max:425px - xs
    576, // 540px  - sm
    768, // 720px - md
    992, // 960px  - lg
    1200, // 1140px - xl
    1400, // 1340px - xxl
    1600, // 1540px - 3xl
    2560 // 1540px - 4xl
];

const getSize = () => {
    const width = window.innerWidth;

    if (width < 320) {
        return 'bs-xxs';
    }

    if (width < 576) {
        return 'bs-xs';
    }

    if (width < 768) {
        return 'bs-sm';
    }

    if (width < 992) {
        return 'bs-md';
    }

    if (width < 1200) {
        return 'bs-lg';
    }

    if (width < 1400) {
        return 'bs-xl';
    }

    if (width < 1600) {
        return 'bs-xxl';
    }

    if (width < 2560) {
        return 'bs-3xl';
    }

    return '4xl';
};

// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1400px

export default function BrowserInfo() {
    const [data, setData] = useState({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        sizeName: getSize(),
        screenSizeList
    });

    useLayoutEffect(() => {
        function updateSize() {
            setData({
                screenWidth: window.innerWidth,
                screenHeight: window.innerHeight,
                sizeName: getSize(),
                screenSizeList
            });
        }
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return data;
}
